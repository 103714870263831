import {
  createRouter,
  createWebHashHistory,
  Router,
  RouteRecordRaw
} from 'vue-router'
// routers
import imgsRoutes from './module/imgs.router'
import commonRoutes from './module/common.router'
import policyRoutes from './module/policy.router'
import { store } from '@/store/index.store'
import i18n from '@/language/index'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/binding',
    name: 'Binding',
    component: () => import('@/views/binding/binding.vue'),
    children: [
      {
        path: 'bindForm',
        name: 'BindForm',
        meta: {
          disableBack: true
        },
        component: () => import('@/views/binding/bindForm.vue')
      },
      {
        path: 'bindTips',
        name: 'BindTips',
        meta: {
          disableBack: true
        },
        component: () => import('@/views/binding/bindTips.vue')
      },
      {
        path: 'bindError',
        name: 'BindError',
        component: () => import('@/views/binding/bindError.vue')
      },
      {
        path: 'authoriseTips',
        name: 'authoriseTips',
        meta: {
          disableBack: true,
          directOpen: true
        },
        component: () => import('@/views/binding/authoriseTips.vue')
      },
      {
        path: 'directLogin',
        name: 'directLogin',
        component: () => import('@/views/binding/directLogin.vue')
      },
      {
        path: 'notCheckTips',
        name: 'notCheckTips',
        component: () => import('@/views/binding/notCheckTips.vue')
      }
    ]
  },
  {
    path: '/policy/policyService',
    name: 'policyService',
    meta: {
      disableBack: true,
      directOpen: true,
      keepAlivePageList: []
    },
    component: () => import('@/views/policy/policyService/policyService.vue')
  },
  {
    path: '/fileUpload/index',
    name: 'fileUpload',
    component: () => import('@/views/fileUpload/index.vue')
  },
  {
    path: '/other/faq',
    name: 'otherFaq',
    component: () => import('@/views/other/Qa.vue')
  },
  {
    path: '/languageSwitch/index',
    name: 'languageSwitch',
    component: () => import('@/views/languageSwitch/index.vue')
  },
  ...imgsRoutes,
  ...commonRoutes,
  ...policyRoutes,
]

const router: Router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { top: 0 }
  }
})

function pushState() {
  window.history.pushState(null, null, document.URL)
}

// 判断能否合法直接打开
let lawful = false

router.beforeEach((to, from, next) => {
  if (from.meta.disableBack) {
    window.removeEventListener('popstate', pushState)
  }
  if (to.meta.directOpen) {
    lawful = true
  }
  // 如果非mock模式 且 非法进入，返回首页
  if (import.meta.env.MODE !== 'mock') {
    if (!lawful) {
      if (to.path?.indexOf('caseReport') >= 0) {
        // 非法直接打开理赔报案，回理赔报案首页
        // router.replace('/caseReport/index')
      } else {
        // 非法打开其他页面回到首页
        router.replace('/policy/policyService')
      }
    }
  }
  next()
})

router.afterEach((to, from) => {
  if (to.meta.disableBack) {
    history.pushState(null, null, document.URL)
    window.addEventListener('popstate', pushState)
  }
})

export default router
