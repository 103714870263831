<template>
  <div class="id-card-ocr">
    <posHeader>
      <p>{{ title || '身份证验证'}}</p>
    </posHeader>

    <CardOCR
      :type="idvConfig.type"
      useTwoImage
      @handleFirstBG="handleFirstBG"
      @handleSecondBG="handleSecondBG"/>

    <div class="button-fixed" v-if="firstBG && secondBG">
      <div role="button" class="btn" :class="{ disable: false }" @click="Confirm">下一步</div>
    </div>

  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from "vue-router"
import apiListAPI from '@/api/apiListAPI'
import {
  loading, Session, showModal, IDVcallback
} from '@/utils/commonMethod'
import SK from '@/common/constant/sessionStorageKey'
import IdvConfig from '@/common/components/idv/bean/IdvConfig'
import CardOCR from './CardOCR.vue'
import LK from '@/common/constant/localStorageKey'
import SN from '@/common/constant/serviceName'
import IdTypeEnum from '@/common/constant/IdTypeEnum'
import { store } from '@/store/index.store'
// 获取idv配置
let idvConfig: IdvConfig = store.state.idv
const router = useRouter()

const title = ref(idvConfig.title)
// 身份证正面
const firstBG = ref("")
// 身份证反面
const secondBG = ref("")
const handleFirstBG = (value) => {
  firstBG.value = value
}
const handleSecondBG = (value) => {
  secondBG.value = value
}
onMounted(() => {
})
// 目前只有更新证件有效期是传update，后端就不会校验证件是否过期
const getOperation = (serviceName: string) => {
  let operation
  switch (serviceName) {
    case SN.UPDATE_ID_CARD_DATE:
      operation = 'update'
      break
    default:
      operation = 'verify'
      break
  }
  return operation
}
const Confirm = async () => {
  loading.show()
  const operation = getOperation(idvConfig.serviceName)
  const params = {
    imagesBase64: [firstBG.value, secondBG.value],
    uploadFlag: idvConfig.idUploadFlag,
    operation,
    serviceName: idvConfig.serviceName, // 后端没要求这个参数，目的是方便看log
    ...idvConfig.otherData
  }
  try {
    const resp = (await apiListAPI.identificationPapersBase64(params)).data
    console.log('resp', resp)
    if (resp.code === 0) {
      // TODO 如果是update，那么不能有效期起期与止期都是一样的。
      // 如果 operation 是 update, 那么需要校验OCR后的起期和止期是否一致，一致的话阻断
      if (operation === 'update') {
        const oldDate = Session.get(SK.ID_NUMBER_EXPIRY_DATE)
        const iDExpDatestatus = checkDate(oldIDExpDate(oldDate), getIDExpDate(resp.data.idNumberExpiryDate))
        const iDStartDatestatus = checkDate(oldIDStartDate(oldDate), getIDStartDate(resp.data.idNumberExpiryDate))
        if (!iDExpDatestatus && !iDStartDatestatus) {
          showModal({
            title: '提示',
            content: '证件有效期不能与原有效期一致。',
            confirmText: '知道了',
          })
          return
        }
      }
      // 改成去sessionStorage里取？IDV config里取。等更新证件有效期测试的时候改
      Session.set((idvConfig.saveKeys && idvConfig.saveKeys.obverseKey) || SK.IDV_DATA_ZIMAGENAME, resp.data.zimageName)
      Session.set((idvConfig.saveKeys && idvConfig.saveKeys.reverseKey) || SK.IDV_DATA_FIMAGENAME, resp.data.fimageName !== resp.data.zimageName || resp.data.fimageName ? resp.data.fimageName : '')
      Session.set(SK.IDV_DATA_INFODATA, {
        idNumber: resp.data.idNumber,
        idNumberExpiryDate: resp.data.idNumberExpiryDate,
        idTypeName: resp.data.idTypeName,
        clientName: resp.data.clientName,
        idType: resp.data.idType
      })
      if (idvConfig.useFace === false) {
        IDVcallback(idvConfig)
      } else {
        // 去确认页
        router.push('/common/idv/cn/idCardOCRConfirm')
      }

    } else {
      showModal({
        content: resp.message,
        showCancel: false
      })
    }
  } catch (e) {
    console.log(e)
  } finally {
    loading.hide()
  }
}

// 如果有效期没改变则popup
const checkDate = (o, n) => {
  const Otime = new Date(o).getTime()
  const Ntime = new Date(n).getTime()
  let type = false
  if (Otime === Ntime) {
    type = false
  } else {
    type = true
  }
  return type
}

const oldIDStartDate = (str: string = '') => {
  let val
  if (str.indexOf('-') !== -1) {
    const len = str.indexOf('-')
    val = str.slice(0, len)
  } else {
    val = ' '
  }
  return val
}

const oldIDExpDate = (str: string = '') => {
  let val
  if (str.indexOf('长期') !== -1) {
    val = '9999/12/31'
  } else {
    const len = str.indexOf('-') + 1
    val = str.slice(len)
  }
  return val
}

const getIDStartDate = (str: string = '') => {
  let val
  if (str.indexOf('-') !== -1) {
    const len = str.indexOf('-')
    val = str.slice(0, len).replace(/\./g, '/')
  } else {
    val = ' '
  }
  return val
}

const getIDExpDate = (str: string = '') => {
  let val
  if (str.indexOf('长期') !== -1) {
    val = '9999/12/31'
  } else {
    const len = str.indexOf('-') + 1
    val = str.slice(len).replace(/\./g, '/')
  }
  return val
}

</script>

<style scoped lang="scss">
@import "@/style/fragment/button-fixed";

.id-card-ocr {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: white;
  overflow: auto;
  text-align: left;

  .header-img {
    width: 18px;
  }

  .header-right {
    font-size: 13px;
    color: #333;
  }
}

</style>
