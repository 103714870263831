/**
 * sessionStorage 用到的key，需要先在这里定义，方便管理 <br/>
 * 值需要指定是字符串，且不要重复
 */
enum SK {
  /** pinfOpenId */
  PINF_OPENID = 'pos_pinfOpenId',
  /** 绑定人角色 */
  BIND_PERSON_ROLE = 'bindPersonRole',
  /** 绑定登录相关 */
  BIND_RESULT_CODE = 'bindResultCode',
  /** 快捷绑定缓存 */
  BIND_QUICK_INPUT = 'BIND_QUICK_INPUT',
  /** 绑定成功标识 - 长者提醒需要用。弹出提示之后，就remove掉 */
  FLAG_BIND_SUCCESS = 'FLAG_BIND_SUCCESS',
  /** 首页检查证件过期，检查合同签收的flag */
  FLAG_CHECK_ID_EXPIRE_AND_SIGNATURE = 'FLAG_CHECK_ID_EXPIRE_AND_SIGNATURE',
  /** 入口标记 */
  ENTRY_FLAG = 'entry_flag',
  /** 客户名称 */
  CLIENT_NAME = 'clientName',
  /**
   * 证件类型
   */
  ID_TYPE = 'idType',
  /** 银行卡组件结果 */
  BANK_ACCOUNT_INFO = 'bankAccountInfo',

  /** 选中的银行信息 */
  BANK_INFO = 'bankInfo',

  /** 选中的贷款原因信息 */
  LOAN_REASON_INFO = 'loanReasonInfo',

  /** 红利动作跳转 */
  DIVIDEND_RESULT_ACTION = 'dividend_result_action',

  /** 结果页数据 */
  RESULT_DATA = 'resultData',

  /** 保单列表数据 */
  DIVIDEND_CHANGE_POLICY_DETAIL = 'dividendChangePolicyDetail',
  DIVIDEND_CHANGE_POLICIES = 'dividendChangePolicies',
  DIVIDEND_WITHDRAW_POLICIES = 'dividendWithdrawPolicies',
  PDF_TOKEN = 'pdf_token',
  DATE_START_TEXT = "dateStartText",
  HISTORY_LIST = 'historyList',
  PDFJS_HISTORY = 'pdfjs.history',
  RISK_TYPE = 'riskType',
  PAY_INFO = "payInfo",
  FLAG_FROM_WXPAY_SIGN = "from_wxpay_sign", // 从签约页面返回的flag
  ALLOW_RE_APPLY_OBJ = "allowReApplyObj",
  POLICY_NUMBER = 'policyNumber',
  POLICY_LIST_ITEM2 = 'policy-list-item',
  // idv配置
  IDV_CONFIG = 'idv_config',
  /** 进入idv之前设置的5个参数 */
  IDV_DATA_SERVICENAME = 'idv_data_serviceName',
  IDV_DATA_ID_UPLOADFLAG = 'idv_data_id_uploadFlag',
  IDV_DATA_BACK_2_SERVICE_URL = 'idv_data_back_2_service_url',
  IDV_DATA_FACE_UPLOADFLAG = 'idv_data_face_uploadFlag',
  IDV_DATA_NAVIGATEPAGE = 'idv_data_navigatePage',

  /** flag for idv */
  FORWARD_FLAG = 'forwardFlag',

  /** 获取idv图片的名称 */
  IDV_DATA_ZIMAGENAME = 'idv_data_zimageName',
  IDV_DATA_FIMAGENAME = 'idv_data_fimageName',
  IDV_DATA_FACEIMAGENAME = 'idv_data_faceimageName',
  IDV_DATA_INFODATA = 'idv_data_infoData',

  /** 获取实人认证的数据 */
  ALI_IDV_CERTIFYID = 'ali_idv_certifyid',

  /** 可回溯相关 */
  REPLAY_SESSION_KEY = 'ANT_REPLAY_SESSION',
  REPLAY_CONFIG_KEY = 'ANT_REPLAY_CONFIG',
  REPLAY_STATUS_KEY = 'ANT_REPLAY_STATUS',

  /** 选中的投资产品 */
  INVESTMENT_PRODUCT_INFO = 'investmentProductInfo', // 1
  BEGIN_DATE_VAL_TEXT1 = 'beginDateValText1', // 2
  END_DATE_VAL_TEXT1 = 'endDateValText1', // 3

  VAS_HSBC_SSO = 'HSBCsso',
  /** fundSwitch数据 */
  ACCT_VAL_DETAILS = 'acctValDetails',
  ROLL_DATA = 'rollData',
  IS_OVER_DUE_OBJ = 'isOverDueObj',
  IS_VALID = 'isValid',
  POLICY_LIST_ITEM = 'policyListItem',
  IS_DIGITAL = 'isDigital',
  TYPE_CODE = 'typeCode',
  POLICY_DETAILS = 'policyDetails',
  RESET_DATA = 'resetData',
  GET_ACCOUNT_CONVERSION_LIST = 'getAccountConversionList',

  /** fundChange数据 */
  RESET_DATAS = 'resetDatas',
  ACCOUNT_CONVERSION_LOCATION = "accountConversionLocation",
  GET_CHANGE_INVEST_RATIO_LIST = "getChangeInvestRatioList",

  /** 退保，犹豫期退保 */
  SURRENDER_LIST = 'surrenderList',
  CANCELLATION_LIST = 'cancellationList',
  SURRENDER_MSG = 'surrenderMsg',
  SURRENDER_POLICY = 'surrender-policy',
  CANCELLATION_POLICY = 'cancellation-policy',
  GET_CLIENT_INFO_MSG = 'getClientInfoMsg',
  REASON_ITEM = 'reasonItem',

  /** 银行卡组件相关 */
  BANK_SELECTED = 'bank_select',
  CITY_SELECTED = 'city_select',

  /**
   * 进度查询
  */
  PROGRESS_DETAIL_REGULAR_TOP_UP = "progressDetailRegularTopUp",
  PROGRESS_ITEM = "progressItem",
  SHOW_POPUP = "showPopup",
  PROGRESS_LISTS = "progressLists",
  /**
   * 身份证后四位验证
  */
  POLICY_ALL_LISTS = "policyAllLists",

  /**
   * 个人信息变更
  */
  CLIENT_INFO_CHANGE_PROGRESS = "clientInfoChangeProgress",
  ID_NUMBER_EXPIRY_DATE = 'idNumberExpiryDate',
  OLD_INFO_DATA = 'oldInfoData',

  /**
   * 保单回访
  */
  RETURN_SELECT_POLICY = "retrunSelectPolicy",

  /**
   * 退保和犹豫期退保
  */
  SURRENDER_POLICY_NUMBER = "surrenderPolicyNumber",
  SURRENDER_REASON = "surrenderReason",
  SURRENDER_REFUND_AMOUNT = "surrendReFundAmount",
  SURRENDER_SURRENDER_TYPE = "surrender",
  SURRENDER_CANCELINSURANCE_TYPE = "cancelInsurance",

  /**
   * 为了方便判断是否回退的路由
   */
  ROUTER_HASH = "routeKey",

  /**
   * 合同签收入口
   */
  POLICY_RECEIPT_ENTRANCE = 'policyReceiptEntrance',
  /**
   * 语言
   */
  GET_LANGUAGE = 'getLanguage',

  AMNT_LIST = 'amntList',
  ACCT_TYPE_SELECT_LIST = 'acctTypeSelectList',

  /**
   * 交易历史
   */
  INSURABLE_INTEREST = 'insurableInterest',

  SUMINSURED_DATA = 'sumInsuredData'
}

export default SK
