import { ComponentInternalInstance, getCurrentInstance } from 'vue'
import dialog from '@/common/components/dialog/dialog'
import PictureList from '@/config/pictureList'
import SK from '@/common/constant/sessionStorageKey'
import { store } from '@/store/index.store'
import router from '@/router/index.router'
import policyListImg01 from '@/assets/images/policy/policy_list_img01.jpg'
import policyListImg02 from '@/assets/images/policy/policy_list_img02.jpg'
import policyListImg03 from '@/assets/images/policy/policy_list_img03.jpg'
import policyListImg04 from '@/assets/images/policy/policy_list_img04.jpg'
import policyListImg05 from '@/assets/images/policy/policy_list_img05.jpg'
import IdvConfig, { aliIdvConfig } from '@/common/components/idv/bean/IdvConfig'
import IdTypeEnum from '@/common/constant/IdTypeEnum'
// import ProStatus from '@/views/progress/data/progressStatus'
// import acctTypes from '@/views/fundSwitch/data/acctTypeList'
// import eventIDMapping from '@/utils/eventIDMapping'
import LK from '@/common/constant/localStorageKey'
import BrowserTypeEnum from '@/common/constant/browserType'
import { isType } from '@/utils/utils'
import ProductCategory from '@/common/constant/productCategory'

export { default as SK } from '@/common/constant/sessionStorageKey'
export function getBrowserType() {
  let browserType = '' // 定义浏览器类型
  const { userAgent } = window.navigator // 取得浏览器的userAgent字符串
  if (userAgent.indexOf('MicroMessenger') > -1) {
    browserType = BrowserTypeEnum.WECHAT
  } else if (userAgent.toLowerCase().includes("android-river-app") || userAgent.toLowerCase().includes("ios-river-app")) { // android-river-app在汇选 android APP 中,ios-river-app在汇选 ios APP 中
    browserType = BrowserTypeEnum.RIVERAPP
  } else if (userAgent.indexOf('UCBrowser') > -1) {
    browserType = BrowserTypeEnum.UC
  } else if (userAgent.indexOf('QQBrowser') > -1) {
    browserType = BrowserTypeEnum.QQ
  } else if (userAgent.indexOf('HuaweiBrowser') > -1) {
    browserType = BrowserTypeEnum.HUAWEI
  } else if (
    userAgent.indexOf('Safari') > -1
    && userAgent.indexOf('Chrome') === -1
  ) {
    browserType = BrowserTypeEnum.SAFARI
  } else if (
    userAgent.indexOf('Chrome') > -1
    && userAgent.indexOf('Safari') > -1
  ) {
    browserType = BrowserTypeEnum.CHROME
  } else {
    browserType = BrowserTypeEnum.OTHERS
  }
  localStorage.setItem(LK.BROWSER_TYPE, browserType)
  return browserType
}

// 判断当前浏览器类型是否APP
export function isRiverApp() {
  return localStorage.getItem(LK.BROWSER_TYPE) === BrowserTypeEnum.RIVERAPP
}

// 获取地址内指定参数
// export function getQueryString(name) {
//   let data = {}
//   let src = window.location.href
//   let index = src.indexOf("?")
//   if (index === -1) {
//     return null
//   }
//   let datastr = src.substring(src.indexOf("?") + 1)
//   let dataArray = datastr.split("&")
//   dataArray.forEach((str) => {
//     let param = str.split("=")
//     let vaule = param[1]
//     data[param[0]] = vaule
//   })
//   return data[name]
// }

// 金额转换成千分位显示   100000 -> 100,000.00
export function toMoney(num: any, precision: number = 2, separatpr: string = ',') {
  let parts
  num = Number(num)
  if (!Number.isNaN(parseFloat(num)) && Number.isFinite(num)) {
    num = (typeof precision !== 'undefined' ? num.toFixed(precision) : num).toString()
    parts = num.split('.')
    parts[0] = parts[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separatpr}`)
    return parts.join('.')
  }
  return num
}

// 获取全局挂载属性、方法
export function useCurrentInstance() {
  const { appContext } = getCurrentInstance() as ComponentInternalInstance
  const proxy = appContext.config.globalProperties
  return {
    proxy
  }
}

// 检查邮件地址/^[\w\d.\-_]+@[\w\d\-_]+\.[\w\d\-_.]*[\w\d\-_]$/g
export function checkEmail(val) {
  const elamilReg = /^[\w.-]+@[\w-]+\.([\w-]+\.)*[a-zA-Z]+$/
  console.log('email--: ', elamilReg, val)
  return !elamilReg.test(val)
}

// 检查数值格式
export function checkNumberFormat(val) {
  const numberFormatReg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
  console.log('--ios', val)
  return !numberFormatReg.test(val)
}

// is IOS？
export function isIOS() {
  const iosReg = /\(i[^;]+;( U;)? CPU.+Mac OS X/
  const u = window.navigator.userAgent
  console.log('---ios', !!u.match(iosReg))
  return !!u.match(iosReg)
}

// 全局loading
export class loading {
  static show() {
    document.getElementById('app_loading').style.display = 'flex'
  }

  static hide() {
    document.getElementById('app_loading').style.display = 'none'
  }
}

/**
 * 弹窗提示
 * @param modalConfig
 */
export function showModal(modalConfig: {
  title?: string, // 标题
  content: string, // 内容
  listArr?: Array<any>, // 展示列表文本
  showCancel?: boolean, // 是否显示【取消】按钮
  hideButton?: boolean, // 是否关闭按钮组。挡板作用。
  cancelText?: string, // 自定义取消按钮文本
  confirmText?: string, // 自定义确定按钮文本
  confirm?: Function, // 点击【确定】回调函数，如果2个回调函数都不传，返回Promise对象
  cancel?: Function // 点击【取消】回调函数，如果2个回调函数都不传，返回Promise对象
}): Promise<any> {
  let dialogConfig = {
    ...modalConfig
  }
  const hasCallback = (dialogConfig.confirm || dialogConfig.cancel) ? true : false
  let promise = dialog(dialogConfig)
  if (hasCallback) {
    return null
  }
  return promise
}

export function setPicture(productCode: any) {
  let imgUrl = ({
    '01': policyListImg01,
    '02': policyListImg02,
    '03': policyListImg03,
    '04': policyListImg04,
    '05': policyListImg05,
  })[PictureList[productCode]] || policyListImg05
  return imgUrl
}

// 封装简化sessionStorage操作
export class Session {

  static set(key, data) {
    let type = Object.prototype.toString.call(data).substr(8).replace(']', '').toLowerCase()
    if (type === 'object' || type === 'array') {
      sessionStorage.setItem(key, JSON.stringify(data))
    } else {
      sessionStorage.setItem(key, data)
    }
  }

  static get(key) {
    const valStr = sessionStorage.getItem(key)
    try {
      const val = JSON.parse(valStr)
      if (typeof val === 'number') {
        return valStr
      }
      return val
    } catch {
      return valStr
    }
  }

  static getThenRemove(key) {
    const val = this.get(key)
    this.remove(key)
    return val
  }

  static remove(key) {
    return sessionStorage.removeItem(key)
  }

  static exist(key) {
    if (sessionStorage.getItem(key)) {
      return true
    }
    return false
  }
}

/**
 * 展示统一错误页
 * @param errorMessage 错误信息wording
 * @param referCode 参考编号
 * @param type 展示内容
 */
export function showErrorPage(errorMessage: string, referCode?, type?) {
  store.commit('updata_state', {
    key: 'errorData',
    value: {
      errorTitle: '温馨提示',
      errorMessage,
      referCode,
      type
    }
  })
  if (type === 'back') {
    router.push('/common/errorBack')
  } else {
    router.push('/common/error')
  }
  loading.hide()
}

export function getIdTypeNameByCode(code: string) {
  const map = {
    0: '身份证',
    1: '护照',
    E: '台湾居民来往大陆通行证',
    F: '港澳居民来往内地通行证',
    J: '港澳台居民居住证',
    K: '外国人永久居留身份证'
  }
  return map[code]
}

/**
 * 开始IDV方法
 * @param idvConfig 配置
 */
export function startIDV(idvConfig: IdvConfig) {
  let idvSwitch = JSON.parse(localStorage.getItem(LK.IDV_SWITCH) || '{}')
  if (!isProduction() && idvSwitch[idvConfig.serviceName]) {
    console.log('绕过idv', idvSwitch)
    IDVcallback(idvConfig)
  } else {
    // 记录调用页的url
    idvConfig.backURL = window.location.hash.substr(1)
    // Session.set(SK.IDV_CONFIG, idvConfig)
    store.commit('idv/UPDATE_IDV_CONFIG', idvConfig)
    if (idvConfig.idType === IdTypeEnum.CN_ID_CARD) {
      router.push('/common/idv/cn/idCardOCR')
    } else {
      router.push('/common/idv/noncn/otherCardOCR')
    }
  }
}

export function IDVcallback(idvConfig: IdvConfig) {
  console.log('idvConfig.nextURL', idvConfig.nextURL)
  if (isType(idvConfig.nextURL, 'Function')) {
    (idvConfig as any).nextURL()
  } else {
    router.push(idvConfig.nextURL)
  }
}

// 获取IDV结果
export function getIDVResult() {
  return {
    zimageName: Session.get(SK.IDV_DATA_ZIMAGENAME),
    fimageName: Session.get(SK.IDV_DATA_FIMAGENAME),
    faceName: Session.get(SK.IDV_DATA_FACEIMAGENAME)
  }
}

/**
 * 开始IDV方法
 * @param idvConfig 配置
 */
export function startAliIDV(idvConfig: aliIdvConfig) {
  let idvSwitch = JSON.parse(localStorage.getItem(LK.IDV_SWITCH) || '{}')
  if (!idvConfig.backURL) {
    idvConfig.backURL = location.hash.split('#')[1] || ''
  }
  if (!isProduction() && idvSwitch[idvConfig.serviceName]) {
    console.log('绕过idv', idvSwitch)
    router.push(idvConfig.nextURL)
  } else {
    // 记录调用页的url
    Session.set(SK.IDV_CONFIG, idvConfig)
    if (idvConfig.idType === IdTypeEnum.CN_ID_CARD) {
      router.push('/common/idv/cn/aliIDV')
    } else {
      router.push('/common/idv/noncn/otherCardOCR')
    }
  }
}

// 获取IDV结果
export function getAliIDVResult() {
  return {
    certifyId: Session.get(SK.ALI_IDV_CERTIFYID),
  }
}

// 获取银行卡账号结果
// export function getBankAccountInfo(): {
//   isHSBC: boolean,
//   bankUserName: string,
//   bankName: string,
//   bankCode: string,
//   bankAccNo: string,
//   bankBranch: string
// } {
//   return Session.get(SK.BANK_ACCOUNT_INFO)
// }

/**
 * 手机号码正则验证
 * @param phone 手机号
*/
export function getPhoneRegExp(phone: any) {
  return /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/g.test(phone)
}

/**
 * 检查身份证号码。如果有错误会返回原因（String）
 * @param num 身份证号码
 * @returns 错误原因（String）
 */
export function checkIdCardNum(num: any, errorMessage?: any) {
  const rgx = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/
  let idCard = num
  let numberWarn = ''
  let msg = errorMessage ? errorMessage : '您填写的证件号码有误请重新填写！'
  if (idCard) {
    if (rgx.test(idCard)) {
      if (idCard.length === 18) {
        let idCardWi = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
        let idCardY = [1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2]
        let idCardWiSum = 0
        for (let i = 0; i < 17; i += 1) {
          idCardWiSum += idCard.substring(i, i + 1) * idCardWi[i]
        }
        let idCardMod = idCardWiSum % 11
        let idCardLast = idCard.substring(17)
        if (idCardMod === 2) {
          if (idCardLast === 'X' || idCardLast === 'x') {
            numberWarn = ''
          } else {
            numberWarn = msg
          }
        } else if (idCardLast === `${idCardY[idCardMod]}`) {
          numberWarn = ''
        } else {
          numberWarn = msg
        }
      } else if (idCard.length === 15) {
        numberWarn = ''
      } else {
        numberWarn = msg
      }
    } else {
      numberWarn = msg
    }
  }
  return numberWarn
}

/**
 * 非大陆证件校验。错误的话返回错误原因（String）
 * @param num 证件号码
 * @param type 证件类型
 * @returns 错误原因（String）
 */
export function checkCredentialsNum(num: any, type: string) {
  let numberWarn = ''
  const credentialsReg = /^[a-zA-Z0-9]{0,20}$/
  if (num) {
    if (credentialsReg.test(num)) {
      numberWarn = ''
    } else {
      switch (type) {
        case 'passport':
          return '请输入正确的护照号码'
        case 'militOfficerCard':
          return '请输入正确的军官证号码'
        case 'homeReturnCertific':
          return '请输入正确的回乡证号码'
        case 'retireMilitOfficerCard':
          return '请输入正确的军官离退休证'
        case 'travelPassforT':
          return '请输入正确的台湾居民来往大陆通行证号码'
        case 'travelPassforHM':
          return '请输入正确的港澳居民来往内地通行证号码'
        case 'birthCertificate':
          return '请输入正确的出生证号码'
        case 'otherCards':
          return '请输入正确的证件号码'
        default:
          break
      }
    }
  } else {
    numberWarn = ''
  }
  return numberWarn
}

/**
 * 澳门身份证明ID校验
 * Macau SAR Resident ID Card, please enter the 7 or 8 digit number on the ID card, such as 9/999999/9 or 9999999(9)
 * @param id 身份证明ID
*/
export function checkMacauId(id) {
  let regex = /^\d\/\d{6}\/\d$/
  let regex2 = /^\d{7}\(\d\)$/
  if (regex.test(id) || regex2.test(id)) {
    return true
  }
  return false
}

/**
 * 香港身份证明ID校验
 * The Hong Kong ID card consists of 3 parts, 1/2 English letters, 6 digit numbers, any digit number from 0 to 9 in brackets or the alphabet A
 * @param id 身份证明ID
*/
export function checkHKId(str) {
  const HKID_VALIDATION = /^[a-zA-Z]{1,2}([0-9]{6}|[0-9]{6}\([0-9A]\))$/
  const rule = /\(|\)/
  if (HKID_VALIDATION.test(str) && rule.test(str)) {
    return true
  }
  return false
}

/**
 * 检查移动电话错误
 * @param num 手机号
 * @param areaCode 区号-文本
 * @returns 错误原因
 */
export function checkPhoneNum(num, areaCode) {
  let phone = num
  let numberWarn = ''
  if (phone) {
    if (areaCode === 'China') {
      if (phone.length === 11) {
        numberWarn = ''
      } else {
        numberWarn = '请输入您在投保时提供的手机号码'
      }
    }
  } else {
    numberWarn = '请输入您在投保时提供的手机号码'
  }
  return numberWarn
}

/**
 * fundSwitch xx转化
*/
export function accAllToNumberFormat(data: any) {
  let l = data.split('.')[0]
  l = `${l.replace(/,/gi, '')}.${data.split('.')[1]}`
  return l
}

/**
 * fundChange xx转化
*/
export function setChangeRatio(data: any) {
  // eslint-disable-next-line no-restricted-syntax
  for (let item of data) {
    item.ratio = Number((Number(item.ratio) * 100).toFixed(0))
  }
  return data
}

/**
 * 变更投资账户价值 Integer转化
 * 变更投资账户价值 Number转化
*/
export function accNumberFormat(s) {
  s = String(s).replace(/[^\d.-]/g, '')
  const l = s.split('.')[0].split('').reverse()
  let t = ''
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length && l[i + 1] !== '-' ? ',' : '')
  }

  return t.split('').reverse().join('')
}

export function dateFormat(data) {
  let date = ''
  if (data) {
    date = data.substring(0, 10)
    if (date.substring(4, 5) === '/') {
      let y = date.split('/')[0]
      y = y ? `${y}年` : ''
      let m = date.split('/')[1]
      if (m.substring(0, 1) === '0') {
        m = m.substring(1, 2)
      }
      m = m ? `${m}月` : ''
      let d = date.split('/')[2]
      if (d.substring(0, 1) === '0') {
        d = d.substring(1, 2)
      }
      d = d ? `${d}日` : ''
      date = y + m + d
    }
  } else {
    date = ''
  }
  return date
}

export function dateFormat2(data) {
  let date = ''
  if (data) {
    date = data.substring(0, 10)
    if (date.substring(4, 5) === '-') {
      let y = date.split('-')[0]
      y = y ? `${y}年` : ''
      let m = date.split('-')[1]
      if (m.substring(0, 1) === '0') {
        m = m.substring(1, 2)
      }
      m = m ? `${m}月` : ''
      let d = date.split('-')[2]
      if (d.substring(0, 1) === '0') {
        d = d.substring(1, 2)
      }
      d = d ? `${d}日` : ''
      date = y + m + d
    }
  } else {
    date = ''
  }
  return date
}

export function dateFormat3(data) {
  let date = ''
  let mouth = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  let mouthEn = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  if (data) {
    date = data.substring(0, 10)
    if (date.substring(4, 5) === '-') {
      let y = date.split('-')[0]
      let m = date.split('-')[1]
      mouth.forEach((item, index) => {
        if (m === item) {
          m = mouthEn[index]
        }
      })
      let d = date.split('-')[2]
      date = `${d} ${m} ${y}`
    }
  } else {
    date = ''
  }
  return date
}

/**
 * 投连追加保费
*/
export function valueTransDisplay(value: string = '', retain: number = 2) {
  let [integer, decimal] = value.split('.')
  if (retain === 2) {
    integer = accNumberFormat(integer)
    integer = integer || '0'
    decimal = decimal || '00'
    return `${integer}.${decimal}`
  }
  return ''
}

/**
 * 进度查询
*/
// export function setStatusName(list) {
//   if (list && list.length) {
//     list.forEach((item1) => {
//       ProStatus.forEach((item2) => {
//         if (item1.intstatus === item2.typeCode) {
//           item1.statusName = item2.typeName
//         }
//       })
//     })
//   }
//   return list
// }

// 进度查询 -
export function setRatioToDecimal(data) {
  // eslint-disable-next-line no-restricted-syntax
  for (let item of data) {
    item.accountInRatio = String(Number((Number(item.accountInRatio) * 100).toFixed(0)))
  }
  return data
}

// 进度查询 -
export function dateProFormat(data) {
  let date = ''
  if (data) {
    date = data.substring(0, 10)
    if (date.substring(4, 5) === '-') {
      let y = date.split('-')[0]
      y = y ? `${y}年` : ''
      let m = date.split('-')[1]
      if (m.substring(0, 1) === '0') {
        m = m.substring(1, 2)
      }
      m = m ? `${y}月` : ''
      let d = date.split('-')[2]
      if (d.substring(0, 1) === '0') {
        d = d.substring(1, 2)
      }
      d = d ? `${y}日` : ''
      date = y + m + d
    }
  } else {
    date = ''
  }
  return date
}

// 进度查询 -
// export function setProStatusName(data) {
//   if (data) {
//     ProStatus.forEach((item2) => {
//       if (data.intstatus === item2.typeCode) {
//         data.statusName = item2.typeName
//       }
//     })
//   }
//   return data
// }

// 进度查询 -
// export function setAcctTypeName(acctData) {
//   if (acctData && acctData.length) {
//     acctData.forEach((item1) => {
//       acctTypes.forEach((item2) => {
//         if (item1.accountType === item2.typeCode) {
//           item1.accountDescriptions = item2.typeName
//         }
//       })
//     })
//   }
//   return acctData
// }

/**
 * 姓名去敏
 * @param name 姓名
 * @returns 格式化后的姓名
 */
export function nameformat(name) {
  let reg = /[a-zA-Z]/i
  if (reg.test(name)) {
    let Ename = name.split(' ')
    let EnameFirstName = ''
    if (Ename.length > 1) {
      for (let i = 0; i < Ename.length - 1; i++) {
        EnameFirstName += `${Ename[i].replace(/./g, '*')} `
      }
      return `${EnameFirstName} ${Ename[Ename.length - 1]}`
    }
    return name.replace(/.(?=.)/g, '*')

  }
  return name.replace(/.(?=.)/g, '*')

}

/**
 * 退出登录，清空缓存
 */
export function logout() {
  const pinfOpenId = sessionStorage.getItem(SK.PINF_OPENID)
  const entryFlag = sessionStorage.getItem(SK.ENTRY_FLAG)
  const policyNumber = sessionStorage.getItem(SK.SURRENDER_POLICY_NUMBER)
  sessionStorage.clear()
  localStorage.clear()
  // 清空绑定页面缓存vuex信息
  store.commit('updata_state', {
    key: 'bindData',
    value: {}
  })
  store.commit('updata_state', {
    key: 'bindErrorCode',
    value: ''
  })
  sessionStorage.setItem(SK.PINF_OPENID, pinfOpenId)
  sessionStorage.setItem(SK.ENTRY_FLAG, entryFlag)
  sessionStorage.setItem(SK.SURRENDER_POLICY_NUMBER, policyNumber)
  router.push('/languageSwitch/index')
}

/**
 * 时间格式处理
 * @param fmt 指定时间格式
 * @param date 时间
 * @returns 处理后时间
 */
export function dataFormat(fmt, date) {
  let ret
  const opt = {
    "y+": date.getFullYear().toString(), // 月份
    "M+": (date.getMonth() + 1).toString(), // 月份
    "d+": date.getDate().toString(), // 日
    "h+": date.getHours().toString(), // 小时
    "m+": date.getMinutes().toString(), // 分
    "s+": date.getSeconds().toString(), // 秒
    "q+": Math.floor((date.getMonth() + 3) / 3).toString(), // 季度
    S: date.getMilliseconds().toString() // 毫秒
  }
  Object.keys(opt).forEach((k) => {
    ret = new RegExp(`(${k})`).exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
    }
  })
  return fmt
}

export function isProduction() {
  return import.meta.env.MODE === 'production'
}

export function isBrowser() {
  const browserType = localStorage.getItem(LK.BROWSER_TYPE)
  const normalBrowser = {}
  normalBrowser[BrowserTypeEnum.SAFARI] = true
  normalBrowser[BrowserTypeEnum.QQ] = true
  normalBrowser[BrowserTypeEnum.UC] = true
  normalBrowser[BrowserTypeEnum.HUAWEI] = true
  return normalBrowser[browserType] ? true : false
}

export function isObject(val, type) {
  const longValType: string = Object.prototype.toString.call(val)
  const valType = longValType.split(' ')[1].substring(-1).replace(']', '')
  return valType === type
}

/**
 * 将&amp;转为&
 * 将ProductCategory进行翻译转换
 */
export function getSymbols(data) {
  if (data) {
    let arr = data.split(" ")
    let language = localStorage.getItem(LK.GET_LANGUAGE)
    let newArr = []
    arr.forEach((item, i) => {
      if (item === '&amp;') {
        if (language === 'EN') {
          newArr.push('&')
        } else {
          newArr.push('及')
        }
      }
      ProductCategory.forEach((element) => {
        if (element.value === item) {
          newArr.push(element[language])
        }
      })
    })
    return language === 'EN' ? newArr.join(' ') : newArr.join('')
  }
  return data
}

/**
 * 设置window title
 */
export function getWindowTitle(data) {
  const languageMap = {
    EN: "HSBC Life",
    TC: "滙豐保險",
    SC: "汇丰保险",
  }
  window.document.title = languageMap[data]
}
