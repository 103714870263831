<template>
  <div :class="idType === IdTypeEnum.CN_ID_CARD ? 'h-screen':'h-auto'">
    <div class="info-tip">
      <img src="@/assets/images/policy/icon_remind1.png" alt="" />
      <p>
        <!-- 同Sam shang \Sam shen 达成一致，代领不修改这部分逻辑 -->
        <span v-if="idType === IdTypeEnum.CN_ID_CARD">
          请务必使用身份证原件进行拍摄验证
        </span>
        <span v-else-if="idType === IdTypeEnum.PASSPORT">
          请务必使用护照原件进行拍摄验证
        </span>
        <span v-else>
          请务必使用身份证件原件进行拍摄验证
        </span>
      </p>
    </div>

    <div class="ID_box" v-if="useTwoImage && !(type === 'beneficiary' && idType === IdTypeEnum.OTHER)">
      <div class="ID_item ">
        <div
          class="ID_item-content iDcard_bg"
          :class="idType === IdTypeEnum.CN_ID_CARD ? 'iDcard_bg_1':'Ecard_bg_1'"
          :style="firstBG">
          <i class="mediaBtn_box mui-icon">
            <img src="@/assets/icon/icon_camera.svg" />
            <capturePhoto :scale="0.65" @handleImage="handleFirstBG" class="mediaBtn"/>
          </i>
        </div>
      </div>

      <div class="ID_item ">
        <div
          class="ID_item-content iDcard_bg"
          :class="idType === IdTypeEnum.CN_ID_CARD ? 'iDcard_bg_2':'Ecard_bg_2'"
          :style="secondBG">
          <i class="mui-icon">
            <img src="@/assets/icon/icon_camera.svg" />
            <capturePhoto :scale="0.65" @handleImage="handleSecondBG" class="mediaBtn"/>
          </i>
        </div>
      </div>

      <div class="IDcard-tips">
        <p v-if="idType === IdTypeEnum.CN_ID_CARD">1. 请依次拍摄身份证⼈像⾯、身份证国徽⾯。</p>
        <p v-else>1. 请依次拍摄<span class="cardType">{{ getIdTypeNameByCode(idType) }}</span>人像面、文字面。</p>
        <p>2. 拍摄时请保证光线充⾜，图像清晰，避免证件反光。</p>
      </div>
    </div>

    <div class="ID_box" v-else>
      <div class="ID_item passportItem">
        <div class="ID_item-content iDcard_bg passport" :style="firstBG">
          <i class="mediaBtn_box mui-icon" aria-label="">
            <img src="@/assets/icon/icon_camera.svg" />
            <capturePhoto :scale="0.65" @handleImage="handleFirstBG" class="mediaBtn"/>
          </i>
        </div>
      </div>
      <div class="IDcard-tips">
        <p v-if="idType === IdTypeEnum.PASSPORT">1. 请拍摄<span class="cardType">{{ getIdTypeNameByCode(idType) }}</span>第一页。</p>
        <p v-else-if="idType === IdTypeEnum.HUKOU">1. 请拍摄<span class="cardType">{{IDcardNameMap[props.type]}}户口簿户籍页</span>。</p>
        <p v-else-if="idType === IdTypeEnum.BIRTHCERTIFICATE">1. 请拍摄<span class="cardType">{{IDcardNameMap[props.type]}}出生证</span>。</p>
        <p v-else>1. 请拍摄<span class="cardType">{{IDcardNameMap[props.type]}}身份证件</span>。</p>
        <p>2. 拍摄时请保证光线充足，图像清晰，避免证件反光。</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import capturePhoto from '@/common/components/capturePhoto.vue'
import { getIdTypeNameByCode } from '@/utils/commonMethod'
import IdTypeEnum from '@/common/constant/IdTypeEnum'

const IDcardNameMap = {
  beneficiary: '未成年受益人的'
}
const props = defineProps({
  // 默认是身份证 - 0
  idType: {
    type: String,
    default: IdTypeEnum.CN_ID_CARD
  },
  type: String,
  useTwoImage: Boolean
})

const myEmit = defineEmits(['handleFirstBG', 'handleSecondBG'])

const firstBG = ref("")
const secondBG = ref("")

const handleFirstBG = (base64) => {
  firstBG.value = `background-size: contain;background-image: url('${base64}')`
  myEmit('handleFirstBG', base64)
}

const handleSecondBG = (base64) => {
  secondBG.value = `background-size: contain;background-image: url('${base64}')`
  myEmit('handleSecondBG', base64)
}
</script>

<style scoped lang="scss">
.text-grey {
  color: #333;
}

.info-tip {
  background-color: #EAEEF4;
  display: flex;
  align-items: center;
  padding: 15px;

  & > img {
    width: 20px;
    margin-right: 10px;
  }

  p {
    font-size: 13px;
    color: #333;
  }
}

.h-screen {

}

.h-auto {
  height: auto;
}

.ID_box {
  padding: 20px 20px 0;

  .ID_item {
    padding: 15px;
    background: #f7f7f7;
    box-sizing: border-box;
    height: 59vw;
    margin-bottom: 15px;
    position: relative;

    >.ID_item-content {
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      >i.mui-icon {
        color: white;
        padding: 10px;
        width: 70px;
        height: 70px;
        border-radius: 50px;

        & > img {
          width: 70px;
        }
      }
    }

    >.ID_item-content::before {
      content: " ";
      border-top: 6px solid #d7d7d5;
      width: 12px;
      display: block;
      height: 12px;
      border-right: 6px solid #d7d7d5;
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
    }

    >.ID_item-content::after {
      content: " ";
      border-bottom: 6px solid #d7d7d5;
      width: 12px;
      display: block;
      height: 12px;
      border-right: 6px solid #d7d7d5;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .passportItem {
    height: 110vw;
  }

  .iDcard_bg_1 {
    background-image: url(@/assets/images/policy/IDcard1.png);
  }

  .iDcard_bg_2 {
    background-image: url(@/assets/images/policy/IDcard2.png);
  }

  .Ecard_bg_1 {
    background-image: url(@/assets/images/policy/Ecard1.png);
  }

  .Ecard_bg_2 {
    background-image: url(@/assets/images/policy/Ecard2.png);
  }

  .passport {
    background-image: url(@/assets/images/policy/passport.png);
  }

  .iDcard_bg {
    background-position: center;
    background-size: 76% auto;
    background-repeat: no-repeat;
  }

  .ID_item::before {
    content: " ";
    border-top: 6px solid #d7d7d5;
    width: 12px;
    display: block;
    height: 12px;
    border-left: 6px solid #d7d7d5;
    overflow: hidden;
    position: absolute;
    z-index: 9;
  }

  .ID_item::after {
    content: " ";
    border-bottom: 6px solid #d7d7d5;
    width: 12px;
    display: block;
    height: 12px;
    border-left: 6px solid #d7d7d5;
    overflow: hidden;
    position: absolute;
    bottom: 15px;
  }

  .IDcard-tips {
    color: #8f8f94;

    >p {
      font-size: 13px;
      line-height: 1;
    }

    >p + p {
      margin-top: 1em;
    }

    .cardType {
      color: #DB0011;
      font-weight: 600;
    }
  }

  .mediaBtn {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 51;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .mediaBtn_box {
    position: relative;
  }
}
</style>
