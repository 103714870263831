export default {
  logo_name: "HSBC Life",
  select_language: "Select language",
  confirm: "Confirm",
  name: "Full name",
  name_placeholder: "Enter your full name with English characters only",
  name_error: "Please enter your full name using English characters and space only.",
  ID_type: "Identity document type",
  ID_card: "ID card",
  macao_card: "Macau SAR Resident Identity Card",
  hk_card: "Hong Kong Identity Card",
  passport: "Passport",
  other_card: "Others",
  ID_card_placeholder: "Please enter the ID number you provided when applying for insurance",
  ID_number: "Identity number",
  idNumber_placeholder: "Enter your identity number",
  phone_number: "Mobile number",
  phone_number_placeholder: "Enter your mobile phone number",
  phone_error: "Please enter a valid mobile phone number",
  ID_card_Travel: "Mainland Travel Permit for Taiwan Residents",
  ID_card_Travel_placeholder: "Please enter the Taiwan resident pass number you provided when applying for insurance",
  phone_tips: "If you cannot enter the mobile phone number reserved in our company, please enter the current mobile phone number you are using.",
  phone_code: "Verification code",
  phone_code_placeholder: "Enter verification code",
  phone_seconds: "Resend in",
  phone_seconds2: "seconds",
  read_agree: "I've read and agreed to the ",
  confirm_accept: "Confirm and accept",
  verification_code: "Get code",
  self_service_terms: "Social Media Community Rules, Terms and Conditions.",
  bind_now: "Registration",
  my_policy: "Policy summary",
  more_services: "Policy services",
  support_title: "Support",
  file_upload: "Submit request",
  contact_customer: "Contact us",
  problem: "FAQs",
  home_tips1: "This platform is designed for use in Macau. If you use this platform overseas, please read the cross-border disclosure:",
  home_tips2: "The services represented within this platform are intended for Macau customers.",
  home_tips3: "This service is provided by HSBC Life (International) Limited, Macau Branch ('the company'), which is authorised and regulated by the Autoridade Monetária de Macau ('AMCM') to carry on long-term insurance business in the Macau SAR.",
  home_tips4: "If you're outside of Macau SAR, we may not be authorised to offer or provide you with the services available through this platform in the country or region you're located or a resident in.",
  home_tips5: "This service isn't intended for distribution or use by any person in any jurisdiction, country or region where the distribution or use of this material is restricted and wouldn't be permitted by law or regulation.",
  log_out: "Log out",
  first_tips: "To register for HSBC Life Macau e-Services, you must have:",
  first_tips_list1: "At least 1 in-force policy with HSBC Life (International) Limited, Macau Branch",
  first_tips_list2: "A valid, registered mobile phone number",
  kind_tips: "Reminder",
  know: "Got it",
  name_tips: "Please enter your full name in a sequence of last name, first name then other names (if any). For example, Chan Tai Man David.",
  idNumber_tips: "If you hold a Macau SAR Resident Identity Card, please enter your identity number, which consists of 7 or 8 numbers. For example, please enter 1/234567/8 or 123456(7). If you hold a Hong Kong Identity Card, please enter your full identity number including the letter and number in brackets. For example, please enter A123456(7).",
  phoneNumber_tips: "Please enter the mobile phone number you provided when you applied for your insurance policy with us.",
  idType_tips: "Please select the identity document type you provided when you applied for your insurance policy with us.",
  idNumberM_error: "Please enter a valid Macau SAR Resident Identity Card number.",
  idNumberHK_error: "Please enter a valid Hong Kong Identity Card number.",
  Macau_areaCode: "Macau SAR +853",
  HK_areaCode: "Hong Kong SAR +852",
  China_areaCode: "Mainland China +86",
  Taiwan_areaCode: "Taiwan +886",
  Canada_areaCode: "Canada +001",
  Singapor_areaCode: "Singapore +65",
  // Re-verification
  Re_title: "Re-verification",
  Re_text1: "Your session has expired due to inactivity. To keep your account secure, we've logged you off.",
  Re_text2: "Please enter the last 4 digits of the identity document you used to register to HSBC Life Macau e-Service. ",
  // bind error
  bind_error1_title: "Incorrect verification code",
  bind_error1: "The verification code you entered is incorrect. Please re-enter the code or get a new code and try again.",
  bind_error2_title: "Mobile phone number doesn't match",
  bind_error2: "The mobile phone number you entered doesn't match our records. Please make sure you've entered the mobile phone number you provided when you took out your insurance policy with us.",
  bind_error3_title: "No in-force policy",
  bind_error3: "According to our records, you don't have an in-force policy with HSBC Life (International) Limited, Macau Branch. You need to take a policy out before you can register for this service.",
  bind_error4_title: "No record",
  bind_error4: "The information you entered doesn't match with any in our records or you don't hold any in-force policy with HSBC Life (International) Limited, Macau Branch. Please make sure you've entered your full name, identity document type and identity number and mobile phone number correctly, and hold an in-force policy with us.",
  bind_error5_title: "No connection",
  bind_error5: "There was a problem connecting to our servers. Please try again.",
  back_btn: "Back",
  vanPicker: {
    confirm: 'Confirm',
    cancel: "Cancel",
  },
  finish_btn: "Finish",
  register_success_title: "Confirmation",
  register_success: "Registration successful",
  register_success_text1: "You can enjoy the services available on WeChat. You'll be redirected to the services page in ",
  register_success_text2: " seconds.",
  register_success_btn: "View services",
  smsErrorText1: "Please enter the verification code you received.",
  smsErrorText2: "The code you entered is invalid. Please check and try again.",
  smsErrorText3: "We've sent the verification code via SMS.",
  // bind_terms
  bind_terms_title: "Social Media Community Rules, Terms and Conditions",
  bind_terms0: "WeChat",
  bind_terms1: "Introducing our official WeChat page for HSBC Life Macau. Visit or like our page on WeChat now.",
  bind_terms2: "Social media community rules",
  bind_terms3: "Connect with us on our new WeChat page. You can simply like our WeChat page to see our latest news, insights, offers and connect with us in Macau.",
  bind_terms4: "Please don't share any personal account information or passwords directly on our WeChat page. To maintain the security and confidentiality of your accounts, we won't respond to or act on any  insurance policy related questions or instructions.",
  bind_terms5: "For insurance policy related questions, please contact us through our customer service hotline.",
  bind_terms6: "HSBC Life Macau WeChat page: hsbclifemacau",
  bind_terms7: "The HSBC Life Macau WeChat page and Policy e-Service are interactive spaces for customer engagement.",
  bind_terms8: "Please remember to follow these simple rules.",
  bind_terms9: "HSBC Life (International) Limited, Macau Branch (incorporated in Bermuda with limited liability) ('HSBC Life') in the Macau Special Administrative Region ('Macau') strongly recommends that you don't share any personal data like your name, address, date of birth, bank account number, credit card number, insurance policy number and relevant information, etc, on any social media channels. Sharing any such information will be at your own risk. HSBC Life will never ask you to post any personal data via social media.",
  bind_terms10: "Please don't post content that promotes, suggests or encourages:",
  bind_terms11: "Gambling or any content related to online casinos, sports books, bingo or poker",
  bind_terms12: "The use of firearms / weapons / ammunition, any illegal drugs, prostitution, pornography",
  bind_terms13: "Nudity, profanity or other adult content, violence, or the use of alcohol or tobacco products",
  bind_terms14: "The taking up of arms against any person, government or entity or otherwise challenge or seek to overthrow any government;",
  bind_terms15: "Or otherwise contains:",
  bind_terms16: "Obscene, indecent, defamatory, libellous, slanderous and/or unlawful content",
  bind_terms17: "Content that infringes upon, or otherwise violates, the rights of any third party, including copyright, trademark, privacy, publicity or other personal or proprietary rights",
  bind_terms18: "Content that is deceptive or fraudulent",
  bind_terms19: "Hateful, or other discriminatory, disparaging or denigrating content, whether directed at an individual, group, or other site users, and whether based upon race, age, gender, disability, sexual orientation, ethnicity, religion, political orientation, national origin, citizenship, ancestry, marital status, veteran status or mental or physical disability or condition, or content that uses adversarial or confrontational tactics to impact a product, service, industry or organization",
  bind_terms20: "Confidential information that violates any obligation of confidentiality;",
  bind_terms21: "Any viruses, spyware, malware, or other malicious components that are designed to harm the functionality of a computer in any way",
  bind_terms22: "'Spam' advertisements or references to non-HSBC Life offers, products, services or websites",
  bind_terms23: "We're happy if you share our content and like our page. We don't automatically like back new fans. If we do like you, this doesn't imply endorsement of any kind from HSBC Life.",
  bind_terms24: "We're actively watching what people are saying to and about HSBC Life on WeChat and value your feedback. However, because of the public nature of the platform, we simply can't respond to or engage on all issues.",
  bind_terms25: "HSBC Life's WeChat presence can't discuss insurance policy information details via direct messages on WeChat. If you have an inquiry requiring the sharing of such information, please send us a message through our customer service hotline.",
  bind_terms26: "Important Reminders",
  bind_terms27: "By using this social media platform, you agree to these Community Rules and the WeChat Terms and Conditions. Social media, with the use of third party communication channels, can't be guaranteed to be timely, secure, error or virus-free.",
  bind_terms28: "We, HSBC Life (International) Limited, Macau Branch (incorporated in Bermuda with limited liability), won't be responsible for any leak or use of information by any third party communication channel, or any other error, omission or loss due to any reason which is outside our control.",
  bind_terms29: "Your use of third party communication channels will be governed by separate direct agreements that you have in place with the third party and its privacy policy. It's your responsibility to comply with them and check from time to time if there are any updates to third party communication channel terms and conditions, policies, service statements, business rules and announcement guidelines, etc. to consider if you continue to find them acceptable.",
  bind_terms30: "We may suspend, or terminate any third party communication channel offered to you for communication with us or change the scope and features of a channel at any time.",
  bind_terms31: "Please make sure that you download third party communication channel apps, software and updates from an official app store and keep apps and software updated to the latest version.",
  bind_terms32: "We'll use collect, store, use and share your personal data in accordance with our ",
  bind_terms32_1: "Personal Information Collection Statement. ",
  bind_terms32_2: "We may share message content and other information about you with third party communication channels used by you to communicate with us and other third parties for the purpose of enabling, maintaining and supporting the communication channel.",
  bind_terms33: "Issued by HSBC Life (International) Limited, Macau Branch (incorporated in Bermuda with limited liability)",
  bind_terms34: "Terms and Conditions",
  bind_terms35: "HSBC Life (International) Limited, Macau Branch (incorporated in Bermuda with limited liability), ('HSBC Life' or the 'Company') in the Macau Special Administrative Region ('Macau') operates channels, pages and accounts on some social media sites to inform, assist and engage with customers. Unless stated otherwise, these are intended for Macau customers and designed for use in Macau. We monitor and record comments and posts made on these channels about the Company so that we can improve our services.",
  bind_terms36: "HSBC Life isn't responsible for any information posted on those sites other than information we have posted ourselves. We don't endorse the social media sites themselves, their products or services, or any information posted on them by third parties or other users. The terms of use and policies of the social media sites used by the Company (eg WeChat) also apply to your use of the Company's channels and pages, and you should ensure that you follow these terms of use and policies as well as those of the Company. You agree that you won't engage in any activities related to this site that are contrary to applicable law, regulation or the terms of any agreements you may have with the Company.",
  bind_terms37: "The content and material on this site are for informational purposes only and is not intended as an offer or solicitation for the purchase of the Company product or service. The Company doesn’t give investment, tax or other professional advice via social media sites. Any communication between you and HSBC Life on this site doesn't alter, change or supersede any of the agreements you may have with us as a customer.",
  bind_terms38: "When you engage with the Company via social media, your personal data may be stored on that social media site's servers, which are outside the control of the Company. For further information, terms and conditions about how we use your personal data, please see our Privacy Policy. Any information posted to such sites may be accessed and read by the general public. It's not an appropriate forum to discuss personal account information or financial arrangements. The Company won't ask you to share personal, account, policy or security information via social media sites, and you shouldn't post such information on those channels. You're permitted to share and comment on content that we post to our social media sites, provided that you don't alter it and that you cite us as the source of that content. All original content that we post on our social media sites is the copyright of the Company and any HSBC Life trademarks, logos or designs are HSBC Life intellectual property.",
  bind_terms39: "Neither the Company nor any other party involved in the creation, production or delivery of information at this site, nor the officers, directors, employees or representatives of any of the foregoing, are liable in any way for any indirect, special, punitive, consequential, or indirect damages (including without limitation lost profits, cost of procuring substitute service or lost opportunity) arising out of or in connection with this site or the use of this site or a linked site or with the delay or inability to use this site or a linked site, whether or not HSBC Life is made aware of the possibility of such damages. This limitation includes, but is not limited to, the transmission of any viruses, data or harmful code that may affect a user's equipment, any incompatibility between this site's files and the user's browser or other site accessing program, or any failure of any electronic or telephone equipment, communication or connection lines, unauthorised access, theft, operator errors, or any force majeure. The Company doesn't guarantee continuous, uninterrupted or secure access to this site or a linked site. The content, accuracy, opinions expressed and other links provided by the linked sites are not necessarily investigated, verified, monitored or endorsed by the Company. The information, software, products and description of services published on the site or a linked site may include inaccuracies or typographical errors, and HSBC Life specifically disclaim any liability for such inaccuracies or errors. Changes are periodically made to the information on the site and linked sites. HSBC Life may make improvements or changes to the site at any time.",
  bind_terms40: "You release the Company, and its parent, affiliate, subsidiaries, officers, directors, agents, employees, service providers, and all other associated with the development and execution of the site, from any and all liability with respect to, or in any way arising from, the site, including, but not limited to, any suspension, modification or cancellation of the site, technical issues, submissions received or posted, including liability for personal injury, death, damages or loss. Furthermore, by entering, viewing, accessing, downloading items from, browsing or otherwise using this site, you agree to indemnify, defend and hold harmless HSBC Life, its agents, service providers and their respective parents, and its and their subsidiaries, affiliates, successors, licensees and assigns, and each of their directors, officers, agents, equity holders and employees, from and against any and all claims, losses, costs, damages, liabilities and costs and expenses (including reasonable attorneys' fees and costs) which arise out of any breach of these terms and conditions or your submissions.",
  bind_terms41: "These terms and conditions are subject to prevailing regulatory requirements.",
  bind_terms42: "These terms and conditions shall be construed and governed in accordance with the laws of Macau.",
  bind_terms43: "HSBC Life's following of any other WeChat user or sharing of any third-party content doesn't equal endorsement.",
  bind_terms44: "HSBC Life currently operates WeChat using the official profile WeChat page in Macau: hsbclifemacau",
  bind_terms45: "In the event of any discrepancy or inconsistency between the Chinese and English versions of these terms and conditions, the English version shall apply and prevail. ",
}
