export default {
  upload_documents: "提交申请",
  upload_tips1: "您可使用此服务提出保单相关服务申请、申请索偿和上传有关文件。",
  upload_tips2: "开始上传文件前，请检查您需提交的服务申请类型是否支持电子提交，以及是否需要在表格上签名。",
  upload_tips3: "是否需要在表格上签名",
  applicable_forms: "适用表格",
  table_title1: "保单服务申请",
  table_title2: "是否需要签名",
  table_title3: "支持电子提交",
  table_td1: "更改客户资料",
  table_td2: "更改受益人",
  table_td3: "更改保单款項方式",
  table_td4: "更改保单保障",
  table_td20: "更改缴付保费指示",
  table_td5: "复效保单",
  table_td6: "退保或取消保单",
  table_td7: "保单贷款／基金提取",
  table_td8: "申请年结通知书、保险单年结摘要、实体医疗卡申领／更换或保单副本",
  table_td9: "补充文件",
  table_td10: "共同汇报标准表格",
  table_title4: "不支持电子提交",
  table_td11: "权益转让作为抵押担保",
  table_td12: "保单权益转让",
  table_td13: "更改汇健优越医疗保险计划保单保障",
  table_title5: "索偿申请",
  table_title6: "支持电子提交",
  table_td14: "汇健优越医疗保险计划预先批核",
  table_td15: "汇健优越医疗保险计划医疗索偿",
  table_td16: "住院现金或失业保障索偿",
  table_td17: "严重疾病、女性保障或末期疾病索偿",
  table_td18: "伤残保障索偿",
  table_td19: "身故赔偿",
  upload_tips4: "如您想提交的表格不支持上传文件功能，请前往分行提交，或邮寄至以下地址：",
  upload_tips5: "汇丰人寿保险（国际）有限公司澳门南湾大马路619号时代商业中心1字楼",
  upload_tips6: "支持电子提交的表格可按以下3个步骤提交指示。",
  step1: "第1步",
  step1_text: "点按“下载表格”后，寻找并下载相关申请表格。",
  step1_button: "下载表格",
  step2: "第2步",
  step2_text: "填写所需资料并签署表格（如适用）。您可以使用设备上的PDF检视工具填写表格。",
  step3: "第3步",
  step3_text: "确认备妥保单号码和已填妥的申请表后，点按“上传文件”，并按汇丰网站上的指示上载适用表格和证明文件。",
  step3_button: "上传文件",
  upload_tips7: "详情请查看WeChat电子保单服务内的常见问题。",
  leave_text1: "您是否确定离开此页面？",
  leave_text2: "如确定离开此页面，您将前往澳门汇丰官网内的表格及文件下载页面。",
  leave_button1: "离开",
  leave_button2: "留在此页",
  leave_text3: '如确定离开此页面，您将前往澳门汇丰官网内的上载文件页面。',
  pageTips: "详情请查看汇丰保险澳门线上客户服务内的常见问题。",
}
