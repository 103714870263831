import { showModal } from "./commonMethod"
// import router from '@/router/index.router'
import SN from '@/common/constant/serviceName'
import SK from '@/common/constant/sessionStorageKey'
import LK from '@/common/constant/localStorageKey'

const Run = "Run" // 录屏运行标记

window.antReplay = null

//
function isReplayDisable() {
  let replaySwitch = localStorage.getItem(LK.REPLAY_SWITCH)
  return replaySwitch === 'Y'
}

function returnMockResult() {
  return new Promise((resolve, reject) => {
    resolve(true)
  })
}

/**
 * 初始化可回溯
 * @param serviceName 服务名
 * @param prodId 产品id
 * @param prodName 产品名
 * @param userId 用户id
 * @param isContinue 是否为继续录屏（拼接）
 * @returns
 */
const replayInit = (serviceName: SN, prodId, prodName, userId, isContinue) => {
  if (isReplayDisable()) return true
  const initConfig = {
    appId: import.meta.env.VITE_REPLAY_MPAASAPPID,
    workspaceId: import.meta.env.VITE_REPLAY_MPAASWORKSPACEID,
    baseGWUrl: import.meta.env.VITE_REPLAY_MPAASBASEGWURL,
    baseStreamURL: import.meta.env.VITE_REPLAY_MPAASBASESTREAMURL
  }
  let config = null
  let session = null
  if (isContinue) {
    config = JSON.parse(sessionStorage.getItem(SK.REPLAY_CONFIG_KEY))
    session = JSON.parse(sessionStorage.getItem(SK.REPLAY_SESSION_KEY))
  } else {
    config = {
      ...initConfig, sceneCode: serviceName, prodId, prodName, userId
    }
  }
  console.log("replay config", config)
  try {
    window.antReplay = new window.antreplaySdk.AntReplay(config, session)
    sessionStorage.setItem(SK.REPLAY_CONFIG_KEY, JSON.stringify(config))
    console.log(`可回溯初始化成功!!!`, window.antReplay)
    return true
  } catch (error) {
    console.log(`可回溯初始化失败：`, error)
    return false
  }
}

/**
 * 可回溯监控
 * @param interruptWording 可回溯中断提示wording
 */
function startMonitor(interruptWording: string, callback: Function) {
  if (isReplayDisable()) return
  window.antReplay.event.on(window.antReplay.ERRORS.ANT_REPLAY_RECORDING_ERROR, (error) => {
    console.log(error, "录屏中断")
    if (error) {
      sessionStorage.removeItem(SK.REPLAY_SESSION_KEY)
      sessionStorage.removeItem(SK.REPLAY_CONFIG_KEY)
      sessionStorage.removeItem(SK.REPLAY_STATUS_KEY)
      window.antReplay.event.all.clear()
      // 提示内容并返回首页
      showModal({
        content: interruptWording,
        showCancel: false,
        confirmText: '知道了',
        confirm() {
          callback()
        }
      })
    }
  })
}

// 开始录制
function antRecordStart(serviceName: string) {
  if (isReplayDisable()) return returnMockResult()
  return new Promise((resolve, reject) => {
    // 全新开始 或 之前录制挂起待拼接
    window.antReplay.start({ pageKey: serviceName }).then(({ replay }) => {
      sessionStorage.setItem(SK.REPLAY_SESSION_KEY, JSON.stringify(replay))
      sessionStorage.setItem(SK.REPLAY_STATUS_KEY, Run)
      console.log("已开启录屏", replay)
      resolve(true)
    }).catch((err) => {
      console.log(err, "录制开启失败")
    })
  })
}

/**
 * 可回溯录制流程开始
 * @param serviceName 服务名
 * @param param1 相关配置
 */
export function replayStart(
  serviceName: SN,
  {
    prodId,
    prodName,
    userId,
    startWording,
    interruptWording,
    interrupt,
    next
  }
) {
  let initStatus = replayInit(serviceName, prodId, prodName, userId, false)
  if (initStatus) {
    showModal({
      content: startWording,
      showCancel: false,
      confirmText: '知道了',
      confirm() {
        antRecordStart(serviceName).then(() => {
          next()
          // 开始监听
          startMonitor(interruptWording, interrupt)
        })
      }
    })
  }
}

/**
 * 继续录制（拼接）
 */
export function replayContinue(interrupt) {
  if (isReplayDisable()) return
  let initStatus = replayInit(null, null, null, null, true)
  if (initStatus) {
    const serviceName = JSON.parse(sessionStorage.getItem(SK.REPLAY_CONFIG_KEY)).sceneCode
    antRecordStart(serviceName).then(() => {
      // 开始监听
      startMonitor('操作记录录制中断，请重新操作', interrupt)
    })
  }
}

// 取消录制
export function replayCancel() {
  if (!window.antReplay) return
  window.antReplay.stop()
  sessionStorage.removeItem(SK.REPLAY_SESSION_KEY)
  sessionStorage.removeItem(SK.REPLAY_CONFIG_KEY)
  sessionStorage.removeItem(SK.REPLAY_STATUS_KEY)
  console.log("录制取消成功")
  window.antReplay.event.all.clear()
  window.antReplay = null
}

// 暂停录制
export const replayPause = () => {
  window.antReplay.pause().then((res) => {
    console.log(res, "录制暂停成功")
  }).catch((err) => {
    console.log(err, "录制暂停失败")
  })
}

// 继续录制（暂停后开始）
export const replayResume = () => {
  window.antReplay.resume().then((res) => {
    console.log(res, "录制继续成功")
  }).catch((err) => {
    console.log(err, "录制继续失败")
  })
}

// 结束录制
export const replayStop = (sceneId, sceneExtInfo) => {
  if (isReplayDisable()) return
  if (window.antReplay) {
    setTimeout(() => {
      window.antReplay.stop({ sceneId, sceneExtInfo }).then((res) => {
        sessionStorage.removeItem(SK.REPLAY_SESSION_KEY)
        sessionStorage.removeItem(SK.REPLAY_CONFIG_KEY)
        sessionStorage.removeItem(SK.REPLAY_STATUS_KEY)
        window.antReplay.event.all.clear()
        window.antReplay = null
        console.log('停止录制成功', res)
      }).catch((err) => {
        console.log('catch 到 失败')
      })
    }, 800)
  } else console.log('停止录制失败')
}

// 停止监听
export const stopMonitor = () => {
  if (window.antReplay) {
    window.antReplay.event.all.clear()
  }
  window.antReplay = null
  console.log('停止监听============----', new Date().toLocaleString())
}
