import { Module } from 'vuex'
import addressInfo from '@/store/bean/addressInfo.model'

const addressModule: Module<addressInfo, any> = {
  namespaced: true,
  state: {
    policyNumbers: ''
  },
  mutations: {
    updata_state(state: any, { key, value }) {
      state[key] = value
    }
  },
  actions: {}
}

export default addressModule
