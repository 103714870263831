export default {
  upload_documents: "Submit request",
  upload_tips1: "This service allows you to raise a policy servicing request or make a claim, and easily upload relevant documents.",
  upload_tips2: "Before you start the uploading, please check which forms are supported by e-submission and require your signature.",
  upload_tips3: "requires a signature",
  applicable_forms: "Applicable forms",
  table_title1: "Policy servicing request",
  table_title2: "Requires signature?",
  table_title3: "Supported by e-submission",
  table_td1: "Change of customer information",
  table_td2: "Change of beneficiary",
  table_td3: "Change of policy payment option",
  table_td4: "Change of policy benefit",
  table_td20: "Change of premium instruction",
  table_td5: "Reinstatement",
  table_td6: "Policy surrender or cancellation",
  table_td7: "Policy value withdrawal / policy loan",
  table_td8: "Request for annual statement, annual summary, physical medical card issuance or replacement, or duplicate policy copy",
  table_td9: "Supplementary documents",
  table_td10: "CRS form",
  table_title4: "Not supported by e-submission",
  table_td11: "Assignment of policy as collateral security",
  table_td12: "Transfer of policy ownership",
  table_td13: "Change of HSBC Flexi Medical Insurance Plan policy benefit",
  table_title5: "Claim request",
  table_title6: "Supported by e-submission",
  table_td14: "HSBC Flexi Medical Insurance Plan pre-authorisation",
  table_td15: "HSBC Flexi Medical Insurance Plan medical claim",
  table_td16: "Hospital cash or unemployment claim",
  table_td17: "Major illness, female benefit or terminal illness claim",
  table_td18: "Disability claim",
  table_td19: "Death claim",
  upload_tips4: "For requests that aren't supported by e-submission, please submit your documents at a branch or send it to the address below:",
  upload_tips5: "HSBC Life (International) Limited 1/F Edf. Comercial Si Toi 619 Avenida da Praia Grande Macau",
  upload_tips6: "For the requests supported e-submission, you can submit them in 3 steps.",
  step1: "Step 1",
  step1_text: "Select 'Download form' then search for and download the forms you need.",
  step1_button: "Download form",
  step2: "Step 2",
  step2_text: "Complete and sign the forms (if required). You can fill in forms electronically using a PDF reader.",
  step3: "Step 3",
  step3_text: "Make sure you have your policy number and completed form with you. Select 'Upload documents' and upload the forms and supporting documents according to the instructions on HSBC website.",
  step3_button: "Upload documents",
  upload_tips7: "For more information, please visit our FAQs on WeChat e-Services Dashboard.",
  leave_text1: "Are you sure you want to leave?",
  leave_text2: "If you confirm, you'll be redirected to Download forms and documents in HSBC Macau website.",
  leave_button1: "Leave",
  leave_button2: "Stay",
  leave_text3: "If you confirm, you'll be redirected to Upload documents in HSBC Macau website.",
  pageTips: "For more information, please visit our FAQs on the HSBC Life Macau e-Services 'Home' tab.",

}
