<template>
  <div class="number-input">
    <div class="input-box" @click.stop.prevent="clickInput($event)">
      <input readonly type="text" :maxlength="maxlength" :placeholder="placeholder" class="input" v-model="value" />
      <div v-if="!value && !placeholder" class="fake-cursor animation-blink"></div>
    </div>
    <van-number-keyboard :show="numberInputShow" :transition="false" :maxlength="maxlength"
      :close-button-text="$t('finish_btn')" @blur="numberInputShow = false" v-model="value" />
  </div>

</template>

<script lang="ts" setup>
import {
  computed, ref, watch, nextTick
} from 'vue'

let numberInputShow = ref(false)
let tempScrollY = ref() // 临时滚动的距离。打开键盘的时候向上滚动，关闭的时候向下

const props = defineProps({
  modelValue: String,
  placeholder: String,
  maxlength: {
    type: Number
  },
  // 偏移量，如果不够还遮住可以传
  delta: {
    type: Number,
    default: 0
  }
})

const emit = defineEmits(['update:modelValue', 'complete'])

const value = computed({
  get() {
    return props.modelValue
  },
  set(inputVal) {
    // console.log('inputVal', String(inputVal))
    emit('update:modelValue', inputVal.toString())
  }
})

let backScrollFlag = false

watch(() => numberInputShow.value, (newVal, oldVal) => {
  // console.log(numberInputShow.value)
  if (!newVal) {
    // setTimeout 100ms 为了解决数字键盘在隐藏遮罩时界面滑动容易然用户误触，所以等用户触发完点击动作再隐藏
    setTimeout(() => {
      if (backScrollFlag) {
        // 滚动回来
        window.scrollBy(0, tempScrollY.value - props.delta)
        backScrollFlag = false
      }
      // 移除占位
      document.querySelector('#temp-div-for-number-input')?.remove()

    }, 100)
    /*    setTimeout(() => {
      if (backScrollFlag) {
        // 滚动回来
        window.scrollBy(0, tempScrollY.value - props.delta)
        backScrollFlag = false
      }
      // 移除占位
      document.querySelector('#temp-div-for-number-input')?.remove()
    }, 100) */
    //
    emit('complete')
  }
})

const clickInput = (event) => {
  const ele = event.currentTarget
  numberInputShow.value = true
  // 添加一个占位div
  const target2WindowTop = ele.getBoundingClientRect().top
  const windowHeight = document.documentElement.clientHeight
  const bottomHeight = windowHeight - target2WindowTop
  const panelheight = 262 + ele.clientHeight
  tempScrollY.value = bottomHeight - panelheight
  // console.log('target2WindowTop', target2WindowTop)
  // console.log('windowHeight', windowHeight)
  // console.log('bottomHeight', bottomHeight)
  // console.log('panelheight', panelheight)
  // console.log('gap', gap)
  // if (tempScrollY.value < 0) {
  const div = document.createElement('div')
  div.setAttribute('id', 'temp-div-for-number-input')
  div.style.height = `${Math.abs(tempScrollY.value) + props.delta}px`
  // div.style.background = 'red'
  document.body.appendChild(div)
  window.scrollBy(0, -tempScrollY.value + props.delta)
  backScrollFlag = true
  // } else {
  //   backScrollFlag = false
  // }
}

// 打开输入法
const popupInputPanel = (event) => {
  clickInput(event)
  numberInputShow.value = true
}

defineExpose({
  popupInputPanel
})

</script>

<style lang="scss" scoped>
@import "@/style/animation/blink";

.van-number-keyboard--with-title {
  border-radius: 0 !important;
}

.number-input {
  position: relative;

  // 增加可触发区域
  width: 100%;

  .fake-cursor {
    width: 100%;
    height: 18px;
    border-left: 1px solid #333;
    position: absolute;
    left: 0;

    // fix: 光标错位
    top: 5px;
  }
}

.input-box {
  .input {
    margin-top: 5px;
    font-size: 14px;
    outline: none;
    border: none;
    width: 100%;
    background: inherit;
  }
}
</style>
