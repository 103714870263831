import { Module } from 'vuex'
import ClientInfo from '@/store/bean/clientInfo.model'

const clientModule: Module<ClientInfo, any> = {
  namespaced: true,
  state: {
    name: 'client-name'
  },
  mutations: {
    UPDATE_CLIENT(state, payload) {
      state = payload
    }
  },
  actions: {}
}

export default clientModule
