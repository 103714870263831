export default {
  policy_period: "保單期",
  policy_details: "保單資料",
  policy_tab1: "基本計劃保障",
  policy_tab2: "附加保障",
  policy_number: "保單號碼",
  total_account: "戶口總值",
  view_details: "查閱基金詳情",
  policyholders_title: "保單持有人及受保人",
  policyholder: "保單持有人（英文姓名）",
  life_insured1: "受保人",
  life_insured2: "（英文姓名）",
  contact_infor: "聯絡資料",
  phone_number2: "手提電話號碼",
  policy_address: "保單通訊地址",
  email_address: "電郵地址",
  sum_insured_title: "保險範圍",
  sum_insured: "保障金額",
  sum_insured_tips: "保障金額視乎您的保單，可指保額、保單金額、名義金額或投保時每月保證年金金額。保額：保額是指在事故發生後，例如是死亡或確診患病，保險公司或會按照保單條款支付金額。保單金額：保單金額是用來決定基本計劃內所需繳付的保費、現金價值連同根據該保單基本計劃內可收取之特別獎賞或終期紅利（如適用）。此金額並不代表身故賠償或保單內的現金價值。名義金額：名義金額是用來決定在此保單的基本計劃下所需繳付的保費，以及您可收取（如有）的現金價值、特別獎賞及保障（額外賠償）的金額。此名義金額並不代表身故賠償金額。投保時每月保證年金金額：每月保證年金金額是於年金期內每月所收取的保證年金金額。詳情請參閱保單條款了解更多。詳情請參閱保單條款。",
  policy_date: "保單生效日期",
  benefit_date: "保障終止日",
  payment_information: "繳費資料",
  premium_date: "保費到期日",
  payment_method: "繳費方式",
  payment_mode: "繳費模式",
  in_force: "	生效中",
  disclaimer_title: "免責聲明",
  disclaimer1: "此頁面顯示的人壽保險計劃由滙豐人壽保險（國際）有限公司承保，於百慕達註冊成立之有限公司。",
  disclaimer2: "所述的金額是指此刻的價值並僅供參考。由於我們只能在實際作出付款時才能釐定某些費用或調整的金額（包括但不限於扣除退保費用及作出捨入調整），所以當您提取保單價值、退保或保單期滿時，您所獲得的實際金額可能會有所不同。您可參閱保單條款以了解更多詳情。",
  disclaimer3: "如保單持有人（或任何保單權益人）於提出保單服務要求時，身處美國境內（暫時或永久），美國法例可能要求我們拒絕特定的保單服務要求。",
  individual_sum: "個別保險範圍",
  individual_tips1: "保障金額視乎您的保單，可指保額、保單金額、名義金額或投保時每月保證年金金額。",
  individual_tips2: "保額：保額是指在事故發生後，例如是死亡或確診患病，保險公司或會按照保單條款支付金額。",
  individual_tips3: "保單金額：保單金額是用來決定基本計劃內所需繳付的保費、現金價值連同根據該保單基本計劃內可收取之特別獎賞或終期紅利（如適用）。此金額並不代表身故賠償或保單內的現金價值。",
  individual_tips4: "名義金額：名義金額是用來決定在此保單的基本計劃下所需繳付的保費，以及您可收取（如有）的現金價值、特別獎賞及保障（額外賠償）的金額。此名義金額並不代表身故賠償金額。",
  individual_tips5: "投保時每月保證年金金額：每月保證年金金額是於年金期內每月所收取的保證年金金額。詳情請參閱保單條款了解更多。",
  individual_tips6: "詳情請參閱保單條款。",
  individual_sum_btn: "返回保單資料",
  sumInsured_tips: "保障金額視乎您的保單，可指保額、保單金額、名義金額或投保時每月保證年金金額。保額：保額是指在事故發生後，例如是死亡或確診患病，保險公司或會按照保單條款支付金額。保單金額：保單金額是用來決定基本計劃內所需繳付的保費、現金價值連同根據該保單基本計劃內可收取之特別獎賞或終期紅利（如適用）。此金額並不代表身故賠償或保單內的現金價值。名義金額：名義金額是用來決定在此保單的基本計劃下所需繳付的保費，以及您可收取（如有）的現金價值、特別獎賞及保障（額外賠償）的金額。此名義金額並不代表身故賠償金額。投保時每月保證年金金額：每月保證年金金額是於年金期內每月所收取的保證年金金額。詳情請參閱保單條款了解更多。詳情請參閱保單條款。",
  whole_life: "終身",
  price: "價格",
  units: "單位",
  total_fund: "基金結餘總額",
  disclaimer4: "所有投資選擇或基金價格均按照最新的結算日計算，並僅供參考。",
  disclaimer5: "人壽保單由滙豐人壽保險（國際）有限公司澳門分公司（「本公司」）承保，本公司獲澳門金融管理局（「澳門金管局」）授權及受其監管，於澳門特別行政區經營長期保險業務。本公司是於百慕達註冊成立之有限公司。本公司為滙豐集團旗下從事承保業務的附屬公司之一。以上計劃由本公司所承保而非香港上海滙豐銀行有限公司之產品，並只擬在澳門特別行政區銷售。",
  disclaimer6: "您的利益受本公司的信貸風險所影響。支付的保費將成為本公司資產的一部分，您對任何該等資產均沒有任何權利或擁有權。如追討賠償，您只可向本公司追索。對於滙豐人壽保險（國際）有限公司與您之間因銷售過程或處理有關交易而產生的合資格爭議（定義見金融消費糾紛調解計劃的受理範圍），本公司須與您進行金融消費糾紛調解計劃程序；此外，有關涉及您上述保單條款及細則的任何糾紛，將直接由本公司與您共同解決。",
  address_tips: "請更新您的電郵地址",
  joint_policyholder: "聯名持有人（英文姓名）",
  per_Day: "每日",
  no_plan: "此保障已不再就此計劃生效",
}
