const PictureList = {
  MIB: '01',
  MI1: '01',
  MI2: '01',
  MI3: '01',
  MIC: '01',
  HC2: '01',
  HBA: '01',
  HBB: '01',
  MM1: '01',
  SCA: '02',
  SLA: '02',
  SLB: '02',
  RLB: '02',
  SLC: '02',
  RLC: '02',
  SLT: '02',
  AEB: '03',
  ENB: '03',
  ENC: '03',
  AEC: '03',
  AED: '03',
  END: '03',
  ENE: '03',
  RDI: '03',
  STE: '03',
  AEE: '03',
  UVB: '03',
  WLC: '04',
  WLB: '04',
  WLD: '04',
  WLE: '04',
  UVA: '04',
  TLA: '04',
  TLB: '04',
  TLC: '04',
  DAA: '05',
  DAB: '05',
  DAC: '05',
  ENF: '05',
  DAE: '05',
  RIA: '05',
  RBE: '05',
  RIB: '05',
  RBF: '05',
  RIC: '05',
  DAD: '05',
}

export default PictureList
