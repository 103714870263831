import {
  RouteRecordRaw
} from 'vue-router'

const imgsRoutes: Array<RouteRecordRaw> = [
  {
    path: '/upload/img',
    name: 'imgView',
    component: () => import('@/common/components/upload/previewImg.vue')
  },
]

export default imgsRoutes
