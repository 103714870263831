import { Module } from 'vuex'
import IDVInfo from '@/store/bean/idvInfo.model'
import IdvConfig from '@/common/components/idv/bean/IdvConfig'

const defValues = {
  otherData: undefined,
  backURL: undefined,
  title: '',
  useFace: true,
  type: '',
  saveKeys: {}
}
const policyModule: Module<IdvConfig, any> = {
  namespaced: true,
  state: {
    serviceName: '', // 服务名称，值域参考 serviceName.ts
    idUploadFlag: false, // id是否需要上传LIS标志。微信后端根据这个判断决定是否存在本地
    faceUploadFlag: false, // 人脸图片是否需要上传LIS标志。
    nextURL: '',
    idType: '0', // 证件类型
    otherData: undefined, // 携带的其他数据。例如旧的证件有效期
    backURL: '', // 返回url，调用的时候自动记录
    title: '',
    useFace: true,
    type: '',
    saveKeys: null
  },
  mutations: {
    UPDATE_IDV_CONFIG(state, payload: IDVInfo) {
      console.log('commit---', payload)
      Object.assign(state, defValues, payload)
    },
    /*  updata_state(state: any, { key, value }) {
      state[key] = value
    } */
  },
  actions: {}
}

export default policyModule
