import { App, watch } from "vue"
import { store } from "@/store/index.store"

/**
 * 自定义指令。<br/>
 * 配合vuex属性 store.state.role 使用: store.commit('SET_ROLE', bindPersonRole.value) <br/>
 * @param app vue实例
 */
export default function buildCustomDirective(app: App<Element>) {

  /**
   * 角色判断显示隐藏 - 客户层<br/>
   * 包含即显示 <br/>
   * 用到block元素上
   */
  app.directive('roles-allow', {
    async mounted(el: HTMLElement, binding, vnode, prevVnode) {
      el.style.display = 'none'
      watch(() => store.state.role, (newRole: string) => {
        let role = newRole
        if (!role) return // 如果还没有角色请求下来，继续隐藏
        const roles: string[] = binding.value
        if (roles.includes(role)) {
          el.style.removeProperty('display')
        } else {
          el.style.display = 'none'
        }
      }, {
        immediate: true
      })
    }
  })

  /**
   * 角色判断显示隐藏 - 客户层<br/>
   * 包含即禁止显示 <br/>
   * 用到block元素上
   */
  app.directive('roles-ban', {
    async mounted(el: HTMLElement, binding, vnode, prevVnode) {
      el.style.display = 'none'
      watch(() => store.state.role, (newRole: string) => {
        let role = newRole
        if (!role) return // 如果还没有角色请求下来，继续隐藏
        const roles: string[] = binding.value
        if (!roles.includes(role)) {
          el.style.removeProperty('display')
        } else {
          el.style.display = 'none'
        }
      }, {
        immediate: true
      })
    }
  })

}
