<template>
  <div class="main">
    <div class="session">
      <div>跳转idv测试</div>
      <button @click="next">开始IDV</button>
    </div>

    <div class="session">
      可回溯录制测试（减保）
      <button @click="testReplay('start')">开始录制</button>
      <button @click="testReplay('cancel')">取消录制</button>
      <button @click="testReplay('continue')">拼接继续</button>
      <button @click="testReplay('stop')">停止录制</button>
    </div>

    <div class="session">
      请输入{{ numberInput }}
      <NumberInput :placeholder="'请填写银行卡号'" v-model="numberInput" />
    </div>

    <div class="session">
      请选择图片
      <capturePhoto @handleImage="handleImage" />
    </div>

    <div class="session">
      <div class="info-item-withline-wrap">
        <div class="info-item">
          <div style="padding: 15px;">测试item</div>
        </div>

        <div class="info-item">
          <div style="padding: 15px;">测试item</div>
        </div>

        <div class="info-item">
          <div style="padding: 15px;">测试item</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import {
  replayStart, replayContinue, replayCancel, replayStop
} from '@/utils/replay'
import NumberInput from '@/common/components/NumberInput.vue'
import capturePhoto from '@/common/components/capturePhoto.vue'
import SN from '@/common/constant/serviceName'
import IdTypeEnum from '@/common/constant/IdTypeEnum'
import { startIDV } from '@/utils/commonMethod'

const numberInput = ref('')

const handleImage = (base64) => {
  console.log('父组件拿到', base64)
}

const next = () => {
  startIDV({
    serviceName: SN.UPDATE_ID_CARD_DATE,
    nextURL: '/policy/policyService',
    faceUploadFlag: true,
    idUploadFlag: true,
    idType: IdTypeEnum.CN_ID_CARD,
  })
}

const testReplay = (action: string) => {
  // if (action === 'start') {
  //   replayStart(SN.CASE_REPORT, {
  //     prodId: SN.CASE_REPORT,
  //     prodName: SN.CASE_REPORT,
  //     userId: SN.CASE_REPORT,
  //     startWording: '准备开始可回溯啦，是否同意？',
  //     interruptWording: '中断啦，准备回首页啦',
  //     interrupt() {

  //     },
  //     next() {

  //     }
  //   })
  // }

  if (action === 'cancel') {
    replayCancel()
  }

  if (action === 'continue') {
    replayContinue(() => {
    })
  }

  if (action === 'stop') {
    replayStop('01234568', {
      key: '自定义数据'
    })
  }
}

</script>

<style lang="scss" scoped>
@import "@/style/fragment/info-item-withline-wrap";

.main {
  background: whitesmoke;
}

.session {
  background: white;
  margin: 15px 0;
}
</style>
