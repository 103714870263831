import { Module } from 'vuex'
import phoneInfo from '@/store/bean/phoneInfo.model'

const phoneModule: Module<phoneInfo, any> = {
  namespaced: true,
  state: {
    oldPhone: '',
    newPhone: '',
    confirmType: '',
    phoneIdType: '',
  },
  mutations: {
    updata_state(state: any, { key, value }) {
      state[key] = value
    }
  },
  actions: {}
}

export default phoneModule
