<script lang="ts" setup>
import { onMounted, ref, Ref } from 'vue'
import { loading } from './utils/commonMethod'
import { useRouter } from 'vue-router'

const includePageList: Ref<Array<string>> = ref([])

const router = useRouter()
router.beforeEach((to, from, next) => {
  console.log('to----', to)
  console.log('to.meta----', to.meta)
  // just set keepAlivePageList as empty array in router's meta
  // when you need to clean the cache
  if (to.meta?.keepAlivePageList) {
    includePageList.value = to.meta.keepAlivePageList as Array<string>
  }
  next()
})

onMounted(() => {
  loading.hide()
})

</script>
// keepAlivePageList
<template>
  <RouterView v-slot="{ Component }">
    <KeepAlive :include="includePageList">
      <component :is="Component" />
    </KeepAlive>
  </RouterView>
</template>

<style lang="scss">
#app {}
</style>
