<template>
  <div class="face-verification">
    <posHeader>
      <p>{{ faceTitle }}</p>
    </posHeader>

    <div class="page_content_scroll">
      <div class="face-page">
        <div v-if="!faceStatus">
          <div class="face-banner">
            <img src="@/assets/images/policy/banner_face.png" alt="face" />
          </div>
          <ul class="tips_face">
            <li>
              <div><img src="@/assets/images/policy/tip1_face.png" alt="" /></div>
              <p class="label-color">请垂直紧握手机</p>
            </li>
            <li>
              <div><img src="@/assets/images/policy/tip2_face.png" alt="" /></div>
              <p class="label-color">请勿遮挡脸部</p>
            </li>
            <li>
              <div><img src="@/assets/images/policy/tip3_face.png" alt="" /></div>
              <p class="label-color">请保证光线良好</p>
            </li>
            <li>
              <div><img src="@/assets/images/policy/tip4_face.png" alt="" /></div>
              <p class="label-color">请勿遮挡眼睛</p>
            </li>
          </ul>
          <ul v-if="infoData.idType === IdTypeEnum.CN_ID_CARD " class="text_list">
            <li class="label-color fz-14"><span class="text-red">*</span>为保障您的账户安全，需经过面部识别验证 </li>
            <li class="label-color fz-14"><span class="text-red">*</span>面部识别功能目前仅适用于中华人民共和国二代居民身份证。</li>
          </ul>
          <ul v-else class="text_list">
            <li class="label-color fz-14"><span class="text-red">*</span>为保障您的账户安全，请实时拍摄并上传投保人正面免冠照片 </li>
            <li class="label-color fz-14"><span class="text-red">*</span>请注意，投保人面部照片非为投保人本人照片或照片不清晰将会导致⼈⼯审核不通过。</li>
          </ul>

          <div class="form_box">
            <div class="apply_all">
              <checkBox :checked="status" @click="status = !status"/>
            </div>
            <div v-if="infoData.idType === IdTypeEnum.CN_ID_CARD " class="flex-auto fz-14">
              我同意授权汇丰人寿为安全验证、合规责任履行及风险管理之目的，采集、使用及储存本人的身份证信息，采集和使用本人的人脸图像信息，同意并授权汇丰人寿自行或通过其授权的服务供应商向中国人民银行或公安系统提供或调取本人身份证信息和人脸图像信息进行比对验证，并保证客户的隐私安全。
            </div>
            <div v-else class="flex-auto fz-14">
              我同意并授权汇丰人寿为安全验证、合规责任履行及风险管理之目的，采集、使用及储存本人的面部照片信息，用于与本人留存于汇丰人寿的个人信息进行比对验证。
            </div>
          </div>

          <div class="button-fixed">
            <div
              class="btn"
              role="button"
              :class="{ disable: !status }">
              同意并授权
            </div>
            <capturePhoto :maxHeight="800" :scale="0.8" :front="true" v-if="status" class="mediaBtn_box" @handleImage="handleImage"/>
          </div>
        </div>

        <div v-else class="shoot">
          <div class="shootImg">
            <div class="faceImg" :style="FaceImgBG"></div>
          </div>
          <div class="footer two_btns_box">
            <div class="btn-border">
              <div
                class="btn-content"
              >重新拍摄</div>
                <capturePhoto :maxHeight="800" :scale="0.8" :front="true" v-if="status" class="mediaBtn_box w-screen-1" @handleImage="handleImage"/>
            </div>
            <div class="w-full" @click="updateFace">
              <div
                class="btn-content btn-content1"
              >上传验证</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from "vue-router"
import apiListAPI from '@/api/apiListAPI'
import {
  loading, Session, showModal, IDVcallback
} from '@/utils/commonMethod'
import IdvConfig from '@/common/components/idv/bean/IdvConfig'
import SK from '@/common/constant/sessionStorageKey'
import IdTypeEnum from '@/common/constant/IdTypeEnum'
import checkBox from '@/common/components/checkBox.vue'
import capturePhoto from '@/common/components/capturePhoto.vue'
import { store } from '@/store/index.store'

const router = useRouter()
const route = useRoute()

const infoData = ref(Session.get(SK.IDV_DATA_INFODATA))
// 获取idv配置
let idvConfig: IdvConfig = store.state.idv
const faceTitle = ref('')

onMounted(() => {
  // 如果没有forwardFlag，返回backURL
  if (!Session.get(SK.FORWARD_FLAG)) {
    if (IdTypeEnum.CN_ID_CARD) {
      router.go(-3)
    } else {
      router.go(-2)
    }
    // router.push(idvConfig.backURL)
    return
  }
  Session.remove(SK.FORWARD_FLAG)
  console.log('infoData', infoData)
  faceTitle.value = infoData.value.idType === IdTypeEnum.CN_ID_CARD ? '人脸认证' : '面部照片拍摄'
})

const faceStatus = ref(false)
const FaceImgBG = ref('')
const imgData = ref('')

// 处理图片回调
const handleImage = (base64) => {
  FaceImgBG.value = `background-size: contain;background-image: url('${base64}')`
  imgData.value = base64
  faceStatus.value = true
}

// 是否勾选同意
const status = ref(false)
const ToggleCheckbox = (value) => {
  status.value = value
}

const updateFace = () => {
  if (imgData.value) {
    loading.show()
    const { otherData } = idvConfig
    let params = {
      imageBase64: imgData.value,
      idNumber: infoData.value.idNumber,
      channelType: idvConfig.serviceName,
      uploadFlag: idvConfig.faceUploadFlag,
      token: otherData && otherData.token
    }
    apiListAPI.verifyClientAuth(params).then((resp: any) => {
      loading.hide()
      if (resp.data.code === 0) {
        Session.set(SK.IDV_DATA_FACEIMAGENAME, resp.data.data)
        IDVcallback(idvConfig)
      } else {
        showModal({
          content: resp.data.message,
          showCancel: false
        })
      }
    }).catch((error) => {
      loading.hide()
      console.log(JSON.stringify(error))
    })
  }
}

</script>

<style scoped lang="scss">
@import "@/style/fragment/button-fixed";

.face-verification {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: white;
  overflow: auto;
  text-align: left;

  .header-img {
    width: 18px;
  }

  .header-right {
    font-size: 13px;
    color: #333;
  }

  .fz-14 {
    font-size: 14px;
  }
}

.btn-border {
  border: 1px solid #333;
  width: 100%;
  margin-right: 10px;
}

.text-red {
  color: $red;
}

.label-color {
  color: $label-color;
}

.w-screen-1 {
  width: 50vw !important;
}

.page_content_scroll {
  flex: 1;
  overflow: auto;
}

.face-page {
  display: flex;
  flex-flow: column;
  min-height: calc(100vh - 45px);
  height: auto;
}

.face-banner > img {
  width: 100%;
  height: auto;
  vertical-align: top;
}

.tips_face {
  display: flex;
  flex-wrap: wrap;

  >li {
    box-sizing: border-box;
    width: 50%;
    overflow: hidden;
    text-align: center;
    padding: 15px 0;

    img {
      width: 12.8vw;
      height: auto;
      vertical-align: top;
      margin: 0 auto;
    }
  }
}

.text_list {
  padding: 15px;
  line-height: 24px;
}

.mui-input-row {
  padding-top: 4px;
}

.form_box {
  display: flex;
  border-top: 1px solid #d7d7d7;
  padding: 15px 15px 128px 15px;
  color: #333;
}

.apply_all {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 5px;

  .flex-auto {
    flex: 1;
  }

  >label {
    font-size: 14px;
    padding-left: 1em;
    line-height: 2.5;
  }

  >.check_box {
    border: 1px solid #333;
    width: 20px;
    height: 20px;
    color: #00847f;

    .icon_checkbox {
      width: 1.5em;
      height: 1.5em;
    }
  }
}

.apply_all::after {
  height: 0;
}

.mediaBtn_box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.shoot {
  display: flex;
  flex-flow: column;
  height: calc(100vh - 45px);
  background-color: #efefef;

  .faceImg {
    height: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  >.shootImg {
    padding: 15vw;
    flex: 1;
  }

  .footer {
    display: flex;
    padding: 2vh 5%;
    background: none;
    border-top: 1px solid #f7f7f7;
    position: relative;

    // 避免input溢出
    >.button {
      position: relative;
    }

    .w-full {
      width: 100%;
    }

    .btn-content {
      width: 100%;
      text-align: center;
      height: 43px;
      line-height: 43px;
      font-weight: bold;
    }

    .btn-content1 {
      background: $red;
      color: white;
    }
  }

  .two_btns_box {
    justify-content: space-between;

    .button {
      width: 48%;

      .btn-empty {
        background: none;
        border-color: #333;
        color: #333;
      }
    }
  }
}
</style>
