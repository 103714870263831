import { Module } from 'vuex'

type ImgViewMap = {
  imgUrl: string,
  id: string|number,
  delete?: Boolean
}
type stateMap = {
  imgView: ImgViewMap,
  imgsCache: Array<any>
}

const imgsModule: Module<stateMap, any> = {
  namespaced: true,
  state: {
    imgView: {
      imgUrl: '',
      id: ''
    },
    imgsCache: []
  },
  mutations: {
    updata_state(state: stateMap, { key, value }: {key: string, value: any}) {
      state[key] = value
    },
    updata_imgView(state: stateMap, data: ImgViewMap) {
      state.imgView = data
    }
  },
  actions: {}
}

export default imgsModule
