export default {
  logo_name: "滙豐保險",
  select_language: "選擇語言",
  confirm: "確定",
  name: "英文全名",
  name_placeholder: "請",
  name_error: "請勿輸入英文字母及空格以外的字元。",
  ID_type: "身分證明文件類型",
  ID_card: "身份证",
  macao_card: "澳門特別行政區居民身份證",
  hk_card: "香港身份證",
  passport: "護照",
  other_card: "其他",
  ID_card_placeholder: "請輸入您在投保時提供的身分證號",
  ID_number: "身分證明文件號碼",
  idNumber_placeholder: "請輸入您的身分證明文件號碼",
  phone_number: "手提電話號碼",
  phone_number_placeholder: "請輸入手提電話號碼",
  phone_error: "請輸入正確的手提電話號碼",
  ID_card_Travel: "台灣居民來往大陸通行證",
  ID_card_Travel_placeholder: "請輸入您在投保時提供的台灣居民通行證號",
  phone_tips: "若無法輸入在我司預留的手機號碼，請您輸入目前使用的手機號碼",
  phone_code: "驗證碼",
  phone_code_placeholder: "請輸入驗證碼",
  phone_seconds: "秒後重新獲取",
  read_agree: "我已閱讀並同意",
  confirm_accept: "確認並同意",
  verification_code: "獲取驗證碼",
  self_service_terms: "社交媒體使用規則、使用條款及細則",
  bind_now: "登記",
  my_policy: "保單摘要",
  more_services: "保單服務",
  support_title: "支援",
  file_upload: "提交申請",
  contact_customer: "聯絡我們",
  problem: "常見問題",
  home_tips1: "此平台為於澳門使用而設計。如您在海外使用此平台，請查閱跨境披露聲明：",
  home_tips2: "此平台內所提供的服務僅供澳門客戶使用。",
  home_tips3: "此服務由滙豐人壽保險（國際）有限公司澳門分公司（「本公司」）承保，本公司獲澳門金融管理局（「澳門金管局」）授權及受其監管，於澳門特別行政區經營長期保險業務。",
  home_tips4: "如您不是身處澳門特別行政區，我們無法保證有權透過此平台在您身處或居住的國家或地區提供相關的服務。",
  home_tips5: "就任何人士在任何司法管轄區使用此網站會違反其法律或法規，或本公司未獲發牌或授權在該等司法管轄區提供此平台及／或任何服務而言，本行無意讓任何身處該等司法管轄區的人士使用此平台。",
  log_out: "登出",
  first_tips: '如需登記滙豐保險澳門線上客戶服務，您必須持有',
  first_tips_list1: "至少一份滙豐人壽保險（國際）有限公司澳門分公司發出的有效保單",
  first_tips_list2: "有效的已登記手提電話號碼",
  kind_tips: "注意事項",
  know: "我知道了",
  name_tips: "請按順序：姓氏、名字、其他名字（如有），輸入您身分證明文件上的全名，例如Chan Tai Man David。",
  idNumber_tips: "如屬澳門特別行政區居民身份證，請輸入該證件上的完整證件號碼（包括括號及斜線），例如9/999999/9或9999999(9)。如屬香港身份證，請連同括號輸入該證件上的完整證件號碼（包括括號），例如A123456(X)或AB123456(X)。",
  phoneNumber_tips: "請輸入您投保時提供的手提電話號碼。",
  idType_tips: "請選擇您投保時提供的身分證明文件類型。",
  idNumberM_error: "請輸入正確的澳門特別行政區居民身份證號碼。",
  idNumberHK_error: "請輸入正確的香港身份證號碼。",
  Macau_areaCode: "澳門特別行政區 +853",
  HK_areaCode: "香港特別行政區 +852",
  China_areaCode: "中國內地 +86",
  Taiwan_areaCode: "台灣地區 +886",
  Canada_areaCode: "加拿大 +001",
  Singapor_areaCode: "新加坡 +65",
  bind_error1_title: "驗證碼錯誤",
  bind_error1: "您輸入的驗證碼錯誤。請重新輸入或獲取新驗證碼後再試一次。",
  bind_error2_title: "手提電話號碼紀錄不符",
  bind_error2: "您輸入的手提電話號碼與我們的紀錄不符。請確保您輸入投保時提供的手提電話號碼。",
  bind_error3_title: "未持有有效保單",
  bind_error3: "根據我們的紀錄，您未有於滙豐人壽保險（國際）有限公司澳門分公司持有有效的保單。您需持有至少一份保單，方可登記此服務。",
  bind_error4_title: "沒有相關紀錄",
  bind_error4: "您輸入的資料與我們的紀錄不符。請確保您輸入正確的英文全名、身分證明文件類別及其號碼。",
  bind_error5_title: "無法連線",
  bind_error5: "目前無法連線至伺服器，請重試。",
  back_btn: "返回",
  vanPicker: {
    confirm: '確認',
    cancel: "取消",
  },
  finish_btn: "完成",
  register_success_title: "確認登記",
  register_success: "已成功登記",
  register_success_text1: "您現可於微信平台上使用相關服務。您將於",
  register_success_text2: "秒後自動前往服務頁面。",
  register_success_btn: "查閱各種服務",
  smsErrorText1: "請輸入您已獲得的驗證碼。",
  smsErrorText2: "驗證碼錯誤，請檢查後再試一次。",
  smsErrorText3: "驗證碼已透過短訊發送至您提供的手提電話號碼。",
  // Re-verification
  Re_title: "重新驗證",
  Re_text1: "為保障您的戶口安全，操作已逾時。",
  Re_text2: "請輸入用作登記的身分證明文件號碼最後4位。",
  // bind_terms
  bind_terms_title: "社交媒體使用規則、使用條款及細則",
  bind_terms0: "WeChat",
  bind_terms1: "我們開設了澳門滙豐保險的WeChat專頁。請即瀏覽或讚好我們的專頁",
  bind_terms2: "社交媒體的使用規則",
  bind_terms3: "我們致力與澳門客戶保持緊密聯繫。您可透過讚好我們澳門滙豐保險的WeChat官方專頁，以獲得我們最新的資訊、動向、優惠及與我們互動。",
  bind_terms4: "基於保安和保密的理由，請勿於我們的專頁透露任何個人戶口資料及密碼，我們亦不會回覆任何與保單資料有關的查詢及接受任何銀行指示。",
  bind_terms5: "有關保單資料的查詢，請致電客戶服務熱線與我們聯絡。",
  bind_terms6: "澳門滙豐保險官方賬號：hsbclifemacau",
  bind_terms7: "澳門滙豐保險的WeChat專頁及電子保單服務是用作和客戶聯繫的渠道之一，請您遵守以下列出的簡單規則。",
  bind_terms8: "澳門特別行政區（「澳門」）的滙豐人壽保險（國際）有限公司澳門分公司（註冊成立於百慕達之有限公司）澳門分公司（「滙豐保險」）強烈不建議您於任何社交媒體上，分享屬於您的任何個人資料，如您的姓名、地址、出生日期、銀行戶口號碼、信用卡號碼、保單號碼及有關資料等。若因上述分享而引致損失，您須自行負責。滙豐保險不會要求您透過社交媒體發佈任何您的個人相關資料。",
  bind_terms9: "請勿於發佈內推廣、建議或鼓吹以下行為：",
  bind_terms10: "- 賭博或任何和線上賭場、運動投注、賓果或撲克遊戲相關的內容",
  bind_terms11: "- 槍械、武器、軍火使用或任何違法藥品、性交易或色情",
  bind_terms12: "- 裸露、褻瀆或其他成人內容、暴力，或菸酒產品使用",
  bind_terms13: "- 針對任何特定人士、政府或組織的不當言論，或挑戰或試圖推翻任何國家政府",
  bind_terms14: "發佈也請勿涉及以下議題：",
  bind_terms15: "不當猥褻、毀謗中傷等文字和不合法的內容",
  bind_terms16: "侵犯或違反任何第三方權益的內容，包括版權、商標、私隱、發佈或其他個人或所有權的權利",
  bind_terms17: "誤導或詐騙的內容",
  bind_terms18: "惡意、歧視、輕蔑或詆毀的內容，不論是否針對某個人、團體或其他網站使用者，以及不論是否基於種族、年齡、性別、身心障礙、性取向、宗教民族性、政治黨派、國籍、祖先、婚姻狀況、退伍軍人身份等，或發佈影響某產品、服務、行業或組織的針對性內容",
  bind_terms19: "洩漏機密資料，違反任何保密責任",
  bind_terms20: "任何病毒、駭客與惡意軟體，或其他意圖毀損電腦設備運作的程式",
  bind_terms21: "垃圾廣告訊息或非與滙豐相關的優惠、產品、服務或網頁等",
  bind_terms22: "歡迎您分享我們的內容並給我們讚好，但我們不會在微信上自動讚好任何用戶。若因任何情況我們讚好了您，亦不表示我們作任何形式的認同。",
  bind_terms23: "我們會密切留意大家對於滙豐保險在WeChat上的內容，也非常重視您寶貴的意見。但由於WeChat是公眾平台，我們不方便在此回應或處理您所有的疑問。",
  bind_terms24: "滙豐保險不允許利用以私人訊息討論您的保單或個人資料。有關保單或個人資料的查詢，請致電客戶服務熱線與我們聯絡。",
  bind_terms25: "使用條款及細則",
  bind_terms26: "澳門特別行政區（「澳門」）的滙豐人壽保險（國際）有限公司澳門分公司（註冊成立於百慕達之有限公司）（「滙豐保險」或「本公司」）透過社交媒體的渠道、網頁及賬戶，以通知、協助客戶或與客戶聯繫。除另有說明外，上述服務只適用於澳門及其澳門客戶使用。社交媒體上關於本公司的意見及內容將被記錄，以便我們改善服務。",
  bind_terms27: "除非是本公司發佈的資料，否則本公司不會對任何非本行於社交網站所發表的意見及內容承擔責任。本公司對於社交網站本身、其產品與服務，或任何第三者或其他社交網站使用者於社交網站所發表的任何訊息均不表示確認或作任何保證。使用社交媒體（如WeChat）與本行聯繫，您須確保遵守本公司及社交媒體的使用條款及細則和使用政策，您同意不會在使用本社交網站時參與任何違反適用法律、法規或違反您與本公司之間任何協議條款的活動。",
  bind_terms28: "本公司於本社交網站上的內容只供提供資訊作參考，不構成要約或招攬任何人購買本公司的產品或服務。本公司不會透過社交媒體網站向您提供投資、稅務或其他專業意見。您與本公司於本社交網站上的通訊亦不會改變或取代您作為本公司客戶時與本公司訂立的任何協議。",
  bind_terms29: "當您透過社交媒體與本行聯繫時，您的個人資料可能被儲存在該社交媒體網站的伺服器上，並不受本公司管控。關於本公司如何運用您的個人資料，請參照私隱政策。您發表在任何社交網站上的訊息，一般大眾亦能取得也能夠瀏覽。社交網站平台並不適合您討論個人戶口資料或財務安排。因此，本公司不會在社交媒體網站要求您提供任何個人保單或保安的資料，您也不應在社交網站上張貼該等資料。在不變動本公司發表的內容與註明資料來源的前提下，您可以轉發或回應本公司在本公司社交網站上的內容。所有本公司發布於本行社交網站上的內容，均受本公司的版權所保護。本公司於社交網站上所使用或展示的任何滙豐保險的商標、徽號和設計，均為滙豐保險的知識產權。",
  bind_terms30: "本公司或任何其他參與製作或發表本社交網站資訊的人士、其職員、董事、僱員或代表，不會對因本社交網站、使用本社交網站或其連結網站，或延遲或不能使用本社交網站或其連結網站所造成任何形式之間接、特殊、懲罰或附帶損失而負法律責任（包括但不限於涉及利益損失、尋找替代服務之費用或使用條款及細則損失機會所產生的損失），不論本公司是否知悉有可能會招致該等損失與否。此限制包括但不限於散佈可能對使用者的電子設備造成損害的病毒、資料或有害的程式，檔案與使用者瀏覽器或其他網站連結程式的技術問題，電子設備或電信設備、傳輸或連結網絡的故障，未授權存取、偷竊、操作錯誤或任何不可抗力的因素。本公司不保證與此社交網站或其連結網站的使用是持續、不受干擾或安全的。網站所顯示內容、準確性，意見或提供的其他連結，本公司並無義務調查、核實、監督或確認。社交網站或連結網站上的資料、軟件、產品和服務內容，可能是不完整、錯誤或遺漏內容，本公司在此特別聲明不就該等不完整、錯誤或遺漏負相關責任。本公司將定時或隨時更新及修改網站內容、資訊及連結，以及改善本網站。",
  bind_terms31: "您同意免除本公司及其母公司、附屬公司、子公司、職員、董事、代理、僱員、服務供應商和所有與本網站內容製作執行相關之人士的責任，包括但不限於因暫停、修改、取消本社交網站、技術問題、接受或發佈貼文所造成的，當中包括人身傷害、死亡、損害或損失的責任。當您進入、瀏覽、存取、下載檔案、搜尋或以其他方式使用本網站，您已同意若您的貼文違反本使用條款及細則，造成本公司、其代理、服務供應商和其各自的母公司、子公司、附屬公司、繼承人、被授權人及受讓人，以及其各自的董事、職員、代理、股東、僱員的所有任何申索、損失、成本、損害、法律責任及費用（包含合理的律師費），您均同意負全部賠償之責。",
  bind_terms32: "本條款及細則受現行的監管規定所約束。",
  bind_terms33: "本條款及細則受澳門法律管轄並按其詮釋。",
  bind_terms34: "本公司跟隨任何其他WeChat的使用者、轉推或分享其他任何第三方的內容不等於確定或贊同該等資訊。",
  bind_terms35: "本公司目前在澳門於WeChat的官方專頁為：hsbclifemacau",
  bind_terms36: "本條款及細則的中英文版本如有歧義或不一致，概以英文版本為準。",
}
