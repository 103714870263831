<template>
  <div class="pos-header">
    <!-- 左侧内容 -->
    <div class="header-left">
      <slot v-if="!hideLeft" name="left">
        <img @click="clickBack" class="header-img" src="@/assets/images/policy/icon_back.png" alt="" />
      </slot>
    </div>
    <!-- 标题 -->
    <div class="header-title">
      <slot></slot>
    </div>
    <!-- 右侧内容 -->
    <div class="header-right">
      <slot v-if="!hideRight" name="right">
        <router-link to="/policy/policyService">
          <p class="header-right-text">首页</p>
        </router-link>
      </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useRouter } from "vue-router"

const router = useRouter()
const props = defineProps({
  handleBack: {
    type: Function
  },
  // 隐藏右边按钮
  hideRight: {
    type: Boolean,
    default: false
  },
  // 隐藏左边按钮
  hideLeft: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['clickBack'])

const clickBack = () => {
  if (props.handleBack) {
    props.handleBack()
  } else {
    router.back()
  }
}

</script>

<style scoped lang="scss">
.pos-header {
  background: white;
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  justify-content: space-between;
  text-align: center;
  position: sticky;
  top: -1px;
  z-index: 99;

  &::after {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: 1px;
    content: "";
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
    background-color: #c8c7cc;
  }

  .header-img {
    width: 18px;
  }

  .header-left,
  .header-right {
    flex: 2;

    .header-right-text {
      color: #333333;
      font-size: 14px;
    }
  }

  .header-title {
    flex: 8;
    color: #333;
    font-size: 19px;
    font-weight: bold;
    text-overflow: ellipsis;
    text-transform: none;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
