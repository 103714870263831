import {
  RouteRecordRaw
} from 'vue-router'

const policyRoutes: Array<RouteRecordRaw> = [
  // 保单查询
  {
    path: '/policy/policyInquiry',
    name: 'policyInquiry',
    component: () => import('@/views/policy/policyInquiry/policyInquiry.vue')
  },
  // 保单详情
  {
    path: '/policy/policyDetails',
    name: 'policyDetails',
    component: () => import('@/views/policy/policyDetails/policyDetails.vue')
  },
  // 缴费记录
  {
    path: '/policy/interestAccount',
    name: 'interestAccount',
    component: () => import('@/views/policy/interestAccount/interestAccount.vue')
  },
  // 被保人详情
  {
    path: '/policy/insuredDetails',
    component: () => import('@/views/policy/insuredDetails/insuredDetails.vue')
  },
  // 账户价值详情
  {
    path: '/policy/accountValue',
    component: () => import('@/views/policy/accountValue/accountValue.vue')
  },
]

export default policyRoutes
