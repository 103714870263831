import {
  Button, NumberKeyboard, Picker, Calendar, DatetimePicker, Popup,
  Toast, Switch, ConfigProvider, Slider, Swipe, SwipeItem
} from 'vant'
import 'vant/es/toast/style'

// 动态引入vant 组件
export default function vantImport(app: any) {
  [
    Button, NumberKeyboard, Picker, Calendar, DatetimePicker, Popup,
    Toast, Switch, ConfigProvider, Slider, Swipe, SwipeItem
  ].forEach((vantComponent) => {
    app.use(vantComponent)
  })
}
