import BaseAPI from './base.api'

export default class apiListAPI extends BaseAPI {

  // 理赔 被保险人身份证校验
  static identificationPapersBase64(params?: any) {
    return this.post({
      url: `/proxy/HsbcInshServiceAccount/bind/client/identificationPapersBase64/${params.operation}`,
      params: params,
      statusCode: 200,
      getJsonPath() {
        let resultSucceed = import(`./mock/idvPos/identificationPapersBase64.json`)
        // 非大陆
        // let nonCNResult = import(`./mock/idvPos/ocrNonCNIdCard.json`)
        return resultSucceed
      }
    })
  }

  static uploadCertificates(params?: any) {
    return this.post({
      url: `/proxy/HsbcInshServiceAccount/bind/client/certificatesBase64/verify`,
      params: params,
      statusCode: 200,
      getJsonPath() {
        let resultSucceed = import(`./mock/idvPos/uploadCertificates.json`)
        return resultSucceed
      }
    })
  }

  static verifyClientAuth(params?: any) {
    return this.post({
      url: `/proxy/HsbcInshServiceAccount/bind/client/verifyClientAuth`,
      params: params,
      statusCode: 200,
      getJsonPath() {
        let resultSucceed = import(`./mock/idvPos/verifyClientAuth.json`)
        return resultSucceed
      }
    })
  }

}
