import { Module } from 'vuex'
import PolicyInfo from '@/store/bean/policyInfo.model'

const policyModule: Module<PolicyInfo, any> = {
  namespaced: true,
  state: {
    contractEnquiryTimestamp: '',
    policyNumber: '',
    token: '',
    wechatCode: '',
    flags: {},
    numTime: undefined,
    pdfURL: '',
    fundType: ''
  },
  mutations: {
    UPDATE_POLICY(state, payload) {
      state = payload
    },
    updata_state(state: any, { key, value }) {
      state[key] = value
    }
  },
  actions: {}
}

export default policyModule
