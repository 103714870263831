/**
 * BrowserTypeEnum <br/>
 * 浏览器类型 <br/>
 */
 enum BrowserTypeEnum {

  WECHAT = 'MicroMessenger',
  RIVERAPP = 'RiverAppMP',
  UC = 'UC',
  QQ = 'QQ',
  HUAWEI = 'Huawei',
  SAFARI = 'Safari',
  CHROME = 'Chrome',
  OTHERS = 'Others',

}

export default BrowserTypeEnum
