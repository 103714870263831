
    import initSnapshotPlugin from './tools/snapshot.vconsole.plugin'
    import initHelperPlugin from './tools/helper.vconsole.plugin'
    import { isProduction } from "@/utils/commonMethod"

    console.log('import.meta.env.MODE: ', import.meta.env.MODE)
    let vconsole = null
    if (!isProduction()) {
      import('vconsole').then(({ default: VConsole }) => {
        vconsole = new VConsole()
        const helperPlugin = initHelperPlugin(vconsole, VConsole.VConsolePlugin)
        const snapshotPlugin = initSnapshotPlugin(vconsole, VConsole.VConsolePlugin)
        vconsole.addPlugin(helperPlugin)
        vconsole.addPlugin(snapshotPlugin)
      })
    }
  