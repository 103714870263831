import { createI18n } from 'vue-i18n'
import EN from './en.js'
import ZHSimplified from './zh_simplified.js'
import ZHTraditional from './zh_traditional.js'
import { Locale } from 'vant'
import LK from '@/common/constant/localStorageKey'

const messages = {
  SC: {
    ...ZHSimplified,
  },
  TC: {
    ...ZHTraditional,
  },
  EN: {
    ...EN,
  }
}

Locale.add(messages)

const getLanguage = localStorage.getItem(LK.GET_LANGUAGE)
const useLanguage = getLanguage ? getLanguage : 'TC'

Locale.use(useLanguage)

const i18n = createI18n({
  legacy: false,
  locale: useLanguage,
  allowComposition: true,
  globalInjection: true,
  messages,
  silentTranslationWarn: true,
  missingWarn: false,
  silentFallbackWarn: true,
  fallbackWarn: false,
})

export default i18n
