/**
 * Response Code <br/>
 */
enum RC {
  /** 成功 */
  SUCCESS = 0,
  /** 验证码错误 */
  SMS_VERIFY_FAIL = 2,
  /** 实名校验错误 */
  REAL_NAME_FAIL = -100,
  /** 保单已续保校验错误 */
  RENEWAL_OF_INSURANCE_FAIL = -101,
  /** 系统错误 */
  SYSTEM_ERROR = 100,
  /** token not bind */
  SESSION_NOT_IN_DB = 556,
  /** token过期。缺失openid（可以是微信的openid，可以是pinfapp的openid） */
  SESSION_EXPIRE = 557,
  /** token超时 */
  SESSION_TIMEOUT = 558,
  /** 未登录，无token */
  TOKEN_IS_NULL = 560,
  /** token失效，被踢下线 */
  TOKEN_INVALID = 561,
  /** 传递maskedPinfOpenId为空 */
  NOT_PINF_OPENID = 562,
  /** 当前pinfOpenId和本地token关联的openId不一致 */
  DIFFERENCE_PINF_OPENID = 563,
  /** 绑定登录失败 - 投保时的身份证件为身份证，且在核心未留存手机号 */
  BIND_ERROR_PHONE_LACK = 178,
  /** 绑定登录失败 - 投保时的身份证件为身份证，但留存手机号与核心不符 */
  BIND_ERROR_PHONE_NOT_MATCH = 179,
  /** 登录失败 */
  LOGIN_FAIL = 10,
  /** app实名登录绑定失败 */
  DIRECT_LOGIN_FAIL = 'directLoginFail'
}

export default RC
