import axios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from "axios"
import MockAdapter from 'axios-mock-adapter'
import router from '@/router/index.router'
import ApiConfig from "./base/apiConfig"
import { getBrowserType, loading, showErrorPage } from '@/utils/commonMethod'
import LK from '@/common/constant/localStorageKey'
import RC from '@/common/constant/responseCode'
import BrowserTypeEnum from '@/common/constant/browserType'

axios.defaults.headers.post["Content-Type"] = "application/json"
axios.defaults.headers.get["Content-Type"] = "application/json"

// 扩展配置项
interface AxiosRequestConfig2<D = any> extends AxiosRequestConfig {
  // 避开拦截器的code白名单
  passCodes?: any
}

interface AxiosInstance2 extends AxiosInstance {
  <T = any, R = AxiosResponse<T>, D = any>(config: AxiosRequestConfig2<D>): Promise<R>;

}
interface newRes extends AxiosResponse {
  $state?: string,
  config: AxiosRequestConfig2
}

const instance: AxiosInstance2 = axios.create({
  baseURL: window.location.origin ? window.location.origin : import.meta.env.VITE_HTTP_BASE_URL,
  timeout: 122 * 1000
})

// 获取到的浏览器类型
const browserType = getBrowserType()

// 请求拦截器
instance.interceptors.request.use((config) => {
  config.headers = {
    // contentType: "application/json; charset=utf-8",
    channel: localStorage.getItem(LK.BROWSER_TYPE) || getBrowserType(),
    eServiceToken: localStorage.getItem(LK.JWT_TOKEN),
    // appId: import.meta.env.VITE_APPID,
    ...config.headers
  }
  return config
}, (error) => Promise.reject(error))

// 响应拦截器
instance.interceptors.response.use((response: newRes) => {
  const { config } = response
  const res: newRes = response
  res.$state = ''
  if (import.meta.env.MODE === 'mock') {
    console.log('instance.interceptors.response', response)
  }
  //
  let target = '/policy/policyService'

  /**/
  if (response?.headers?.eservicetoken) {
    localStorage.setItem(LK.JWT_TOKEN, response.headers.eservicetoken)
  }
  if (response.data.code === RC.SUCCESS || (config.passCodes && config.passCodes.includes(response.data.code))) {
    if (response.data.code === RC.SUCCESS) {
      res.$state = 'pass'
    }
  } else {
    switch (response.data.code) {
      case RC.SYSTEM_ERROR:
        loading.hide()
        showErrorPage('暂时未能连接服务，请稍候再试。', RC.SYSTEM_ERROR, 'back2home')
        break
      case RC.SESSION_EXPIRE:
        // 微信专用557，默认不会走到这里，但留个保险
        localStorage.setItem(LK.OAUTHBIND_CODE, response.data.code)
        // 测试APP端触发557的场景
        if (window.location.hash?.indexOf('caseReport') >= 0) {
          // target = '/caseReport/index'
        } else if (browserType === BrowserTypeEnum.RIVERAPP) {
          // target = '/binding/authoriseTips'
        }
        router.push('/languageSwitch/index')
        break
      case RC.TOKEN_IS_NULL:
      case RC.TOKEN_INVALID:
      case RC.SESSION_NOT_IN_DB:
        loading.hide()
        localStorage.removeItem(LK.JWT_TOKEN)
        // }
        router.push('/languageSwitch/index')
        break
      case RC.DIFFERENCE_PINF_OPENID:
        loading.hide()
        localStorage.removeItem(LK.JWT_TOKEN)
        router.push('/languageSwitch/index')
        break
      case RC.NOT_PINF_OPENID:
        loading.hide()
        localStorage.removeItem(LK.JWT_TOKEN)
        router.push('/languageSwitch/index')
        break
      case RC.SESSION_TIMEOUT:
        loading.hide()
        localStorage.setItem(LK.OAUTHBIND_CODE, response.data.code)
        router.push('/binding/directLogin')
        break
      default:
        res.$state = 'pass'
        break
    }
  }
  return res
}, (error) => {
  console.log('系统异常-->', error.response)
  const errMsg = '很抱歉服务暂无响应，请勿重新提交或刷新页面。请致电我们的客户服务热线400-820-8363查询业务申请状态。'
  // const data: any = error.response && error.response.data
  /* const code = data && isObject(data) && data.code
  if (error.config.method.toUpperCase() === 'GET') {
    showErrorPage('暂时未能连接服务，请稍候再试。', code || 'MB000', 'back')
  } else {
    if (code) {
      loading.hide()
      showErrorPage(errMsg, code, 'back2home')
    } else {
      showErrorPage(errMsg, 'MB000', 'back2home')
    }
  } */
  showErrorPage(errMsg, 'MB000', 'back2home')
  return Promise.reject(error)
})

// mockConfigSet 记录已配置mock的url
let mockConfigSet = null
let mockAdapter: any = null
if (import.meta.env.MODE === 'mock') {
  mockAdapter = new MockAdapter(instance, { onNoMatch: "throwException", delayResponse: 800 })
  mockConfigSet = new Set<string>()
}

export default class BaseAPI {

  private static getMockConfigSetKey(url: string, method: string) {
    return `${url}:${method}`
  }

  private static setMockConfig(apiConfig: ApiConfig, method: string) {
    if (mockAdapter && !mockConfigSet.has(this.getMockConfigSetKey(apiConfig.url, method))) {
      mockConfigSet.add(this.getMockConfigSetKey(apiConfig.url, method))
      if (method === 'GET') {
        mockAdapter.onGet(apiConfig.url).reply(async (config: any) => {
          return Promise.resolve([apiConfig.statusCode, (await apiConfig.getJsonPath()).default])
        })
      }
      if (method === 'POST') {
        mockAdapter.onPost(apiConfig.url).reply(async (config: any) => {
          return Promise.resolve([apiConfig.statusCode, (await apiConfig.getJsonPath()).default])
        })
      }
    }
  }

  static get(apiConfig: ApiConfig) {
    this.setMockConfig(apiConfig, 'GET')
    return instance({
      method: 'GET',
      url: apiConfig.url,
      params: apiConfig.params,
      headers: apiConfig.headers,
      passCodes: apiConfig.passCodes
    })
  }

  static post(apiConfig: ApiConfig) {
    this.setMockConfig(apiConfig, 'POST')
    return instance({
      method: 'POST',
      url: apiConfig.url,
      data: apiConfig.params,
      headers: apiConfig.headers,
      passCodes: apiConfig.passCodes
    })
  }

  static postForm(apiConfig: ApiConfig) {
    this.setMockConfig(apiConfig, 'POST')
    return instance({
      method: 'POST',
      url: apiConfig.url,
      params: apiConfig.params
    })
  }

}
