import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import BaseData from '@/store/bean/IbaseData'
import clientModule from './module/client.store.m'
import policyModule from './module/policy.store.m'
import addressModule from './module/address.store.m'
import phoneModule from './module/phone.store.m'
import IDVModule from './module/idv.store.m'
import ImgsModule from './module/imgs.store.m'

export const key: InjectionKey<Store<BaseData>> = Symbol('vuex-baseData')

export const store = createStore<BaseData>({
  state() {
    return {
      idType: "", // 客户证件类型
      bindData: {}, // bindForm页面数据
      pageData: null,
      role: null, // 角色
      bindErrorCode: '', // bindForm报错code
      bindIDVToken: '', // bind触发手机号不匹配给的临时token
      errorData: {}, // error显示数据
      policyRestore: {}, // 合同签收信息
      reportMsg: {}, // 理赔报案
      beneficiaryData: {} // 年金生存金领取人
    }
  },
  getters: {},
  mutations: {
    SET_PAGE_DATA(state, payload) {
      state.pageData = payload
    },
    updata_state(state: any, { key, value }) {
      state[key] = value
    },
    SET_ROLE(state, role) {
      state.role = role
    },
    SET_IDTYPE(state, value) {
      state.idType = value
    }
  },
  actions: {},
  modules: {
    client: clientModule,
    policy: policyModule,
    address: addressModule,
    phone: phoneModule,
    idv: IDVModule,
    imgs: ImgsModule
  }
})

export function useStore() {
  return baseUseStore(key)
}
