<template>
  <div class="other-card-ocr">
    <posHeader>
      <p>{{ title || '身份证件验证' }}</p>
    </posHeader>

    <div class="ocr-content">
      <CardOCR
        @handleFirstBG="handleFirstBG"
        @handleSecondBG="handleSecondBG"
        :idType="idType"
        :type="idvConfig.type"
        :useTwoImage="useTwoImage"
      />

      <div class="main-content">
        <div class="other__box">
          <div class="cb_normal">
            <checkBox :checked="allIsConfirm" @click="allIsConfirm = !allIsConfirm"/>
          </div>
          <span id="input1Label" class="protocol_tips">我知悉拍摄证件不清晰或未使用与本人留存在汇丰人寿的身份证件相一致的证件将会导致⼈⼯审核不通过。</span>
        </div>
        <div class="other__box">
          <div class="cb_normal" >
            <checkBox :checked="otherIsConfrim" @click="otherIsConfrim = !otherIsConfrim"/>
          </div>
          <span id="input2Label"
            class="protocol_tips">我同意并授权汇丰人寿为安全验证、合规责任履行及风险管理之目的，采集、使用及储存本人的身份证件信息，用于与本人留存于汇丰人寿的个人信息进行比对验证。</span>
        </div>
      </div>

      <div class="back-botton" @click="Confirm">
        <div
          class="btn-content"
          :class="btnDisabled ? 'bg-red':'bg-pink'"
        >同意并授权</div>
      </div>
    </div>

  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter, useRoute } from "vue-router"
import apiListAPI from '@/api/apiListAPI'
import {
  loading, Session, showModal, IDVcallback
} from '@/utils/commonMethod'
import SK from '@/common/constant/sessionStorageKey'
import IdvConfig from '@/common/components/idv/bean/IdvConfig'
import capturePhoto from '@/common/components/capturePhoto.vue'
import checkBox from '@/common/components/checkBox.vue'
import CardOCR from './CardOCR.vue'
import IdTypeEnum from '@/common/constant/IdTypeEnum'
import LK from '@/common/constant/localStorageKey'
import { store } from '@/store/index.store'

const router = useRouter()

// 获取idv配置
let idvConfig: IdvConfig = store.state.idv
const title = ref(idvConfig.title)
const idType = ref(idvConfig.idType)
const cardTypes: Array<any> = [IdTypeEnum.CN_ID_CARD, IdTypeEnum.TWJMLWDLTXZ, IdTypeEnum.GAJMLWNDTXZ, IdTypeEnum.GATJMJZZ, IdTypeEnum.WGRYJJLSFZ, IdTypeEnum.OTHER]
const useTwoImage = ref(cardTypes.includes(idvConfig.idType))
onMounted(() => {
})

// 第一个多选框
const allIsConfirm = ref(false)

// 第二个多选框是否已选中
const otherIsConfrim = ref(false)

const btnDisabled = computed(() => {
  let flag = false
  if (allIsConfirm.value && otherIsConfrim.value) {
    // 第一个多选框未勾选
    if ((!useTwoImage.value && firstBG.value) || (useTwoImage.value && (firstBG.value && secondBG.value))) {
      flag = true
    }
  }
  return flag
})

const firstBG = ref("")

const secondBG = ref("")

const handleFirstBG = (value) => {
  firstBG.value = value
}

const handleSecondBG = (value) => {
  secondBG.value = value
}

const Confirm = async () => {
  if (btnDisabled.value) {
    loading.show()
    const params = {
      imagesBase64: [firstBG.value, secondBG.value],
      uploadFlag: idvConfig.idUploadFlag,
      ...idvConfig.otherData
    }
    try {
      const resp = (await apiListAPI.uploadCertificates(params)).data
      if (resp.code === 0) {
        Session.set((idvConfig.saveKeys && idvConfig.saveKeys.obverseKey) || SK.IDV_DATA_ZIMAGENAME, resp.data.zimageName)
        Session.set((idvConfig.saveKeys && idvConfig.saveKeys.reverseKey) || SK.IDV_DATA_FIMAGENAME, resp.data.fimageName !== resp.data.zimageName || resp.data.fimageName ? resp.data.fimageName : '')
        Session.set(SK.IDV_DATA_INFODATA, {
          idNumber: resp.data.idNumber,
          idNumberExpiryDate: resp.data.idNumberExpiryDate,
          idTypeName: resp.data.idTypeName,
          clientName: resp.data.clientName,
          idType: resp.data.idType
        })
        if (idvConfig.useFace === false) {
          IDVcallback(idvConfig)
        } else {
          // 跳转到人脸
          Session.set(SK.FORWARD_FLAG, 'Y')
          router.push({
            path: '/common/idv/noncn/faceVerification'
          })
        }

      } else {
        showModal({
          content: resp.message,
          showCancel: false
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      loading.hide()
    }
  }
}

</script>

<style scoped lang="scss">
.other-card-ocr {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: white;
  overflow: auto;

  .header-img {
    width: 18px;
  }

  .header-right {
    font-size: 13px;
    color: #333;
  }

  .ocr-content {
    height: auto;
    margin-bottom: 15px;

    .main-content {
      margin-bottom: 30px;

      .other__box {
        display: flex;
        margin: 16px 10px 16px 20px;
        line-height: 24px;
        color: #333;

        .cb_normal {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          border-radius: 1px;
          box-sizing: border-box;

          img {
            width: 22px;
          }
        }

        .border1 {
          border: 1px solid #767676;
          box-sizing: padding-box;
        }

        .protocol_tips {
          font-size: 13px;
          flex: 1;
        }
      }
    }
  }

  .back-botton {
    padding: 0 15px;

    .btn-content {
      width: 100%;
      text-align: center;
      color: white;
      height: 45px;
      line-height: 45px;
      font-weight: bold;
    }
  }
}

.bg-red {
  background: $red;
}

.bg-pink {
  pointer-events: none;
  background: $pink;
}

</style>
