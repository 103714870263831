export default {
  policy_period: "保单期",
  policy_details: "保单资料",
  policy_tab1: "基本计划保障",
  policy_tab2: "附加保障",
  policy_number: "保单号码",
  total_account: "账户总值",
  view_details: "查看基金详情",
  policyholders_title: "保单投保人与被保人",
  policyholder: "保单持有人（英文姓名）",
  life_insured1: "受保人",
  life_insured2: "（英文姓名）",
  contact_infor: "联络资料",
  phone_number2: "手机号码",
  policy_address: "保单通讯地址",
  email_address: "电子邮箱",
  sum_insured_title: "保险范围",
  sum_insured: "保障金额",
  sum_insured_tips: "保障金额视乎您的保单，可指保额、保单金额、名义金额或投保时每月保证年金金额。保额：保额是指在事故发生后，例如是死亡或确诊患病，保险公司或会按照保单条款支付金额。保单金额：保单金额是用来决定基本计划内所需支付的保费、现金价值连同根据该保单基本计划内可收取之特别奖赏或终期红利（如适用）。此金额并不代表身故赔偿或保单内的现金价值。名义金额：名义金额是用来决定在此保单的基本计划下所需支付的保费，以及您可收取（如有）的现金价值、特别奖赏及保障（额外赔偿）的金额。此名义金额并不代表身故赔偿金额。投保时每月保证年金金额：每月保证年金金额是于年金期内每月所收取的保证年金金额。详情请参阅保单条款了解更多。详情请参阅保单条款。",
  policy_date: "保单生效日期",
  benefit_date: "保障终止日",
  payment_information: "主险缴费详情",
  premium_date: "保费到期日",
  payment_method: "付款方式",
  payment_mode: "付款模式",
  in_force: "	生效中",
  disclaimer_title: "免责声明",
  disclaimer1: "此页面显示的人寿保险计划由汇丰人寿保险（国际）有限公司承保，于百慕达注册成立之有限公司。",
  disclaimer2: "所述的金额是指此刻的价值并仅供参考。由于我们只能在实际作出付款时才能厘定某些费用或调整的金额（包括但不限于扣除退保费用及作出舍入调整），所以当您提取保单价值、退保或保单期满时，您所获得的实际金额可能会有所不同。您可参阅保单条款以了解更多详情。",
  disclaimer3: "如保单持有人（或任何保单权益人）于提出保单服务要求时，身处美国境内（暂时或永久），美国法例可能要求我们拒绝特定的保单服务要求。",
  individual_sum: "个别保险范围",
  individual_tips1: "保障金额视乎您的保单，可指保额、保单金额、名义金额或投保时每月保证年金金额。",
  individual_tips2: "保额：保额是指在事故发生后，例如是死亡或确诊患病，保险公司或会按照保单条款支付金额。",
  individual_tips3: "保单金额：保单金额是用来决定基本计划内所需支付的保费、现金价值连同根据该保单基本计划内可收取之特别奖赏或终期红利（如适用）。此金额并不代表身故赔偿或保单内的现金价值。",
  individual_tips4: "名义金额：名义金额是用来决定在此保单的基本计划下所需支付的保费，以及您可收取（如有）的现金价值、特别奖赏及保障（额外赔偿）的金额。此名义金额并不代表身故赔偿金额。",
  individual_tips5: "投保时每月保证年金金额：每月保证年金金额是于年金期内每月所收取的保证年金金额。详情请参阅保单条款了解更多。",
  individual_tips6: "详情请参阅保单条款。",
  individual_sum_btn: "返回保单资料",
  sumInsured_tips: "保障金额视乎您的保单，可指保额、保单金额、名义金额或投保时每月保证年金金额。保额：保额是指在事故发生后，例如是死亡或确诊患病，保险公司或会按照保单条款支付金额。保单金额：保单金额是用来决定基本计划内所需支付的保费、现金价值连同根据该保单基本计划内可收取之特别奖赏或终期红利（如适用）。此金额并不代表身故赔偿或保单内的现金价值名义金额：名义金额是用来决定在此保单的基本计划下所需支付的保费，以及您可收取（如有）的现金价值、特别奖赏及保障（额外赔偿）的金额。此名义金额并不代表身故赔偿金额。投保时每月保证年金金额：每月保证年金金额是于年金期内每月所收取的保证年金金额。详情请参阅保单条款了解更多。详情请参阅保单条款。",
  whole_life: "终身",
  price: "价格",
  units: "单位",
  total_fund: "基金结余总额",
  disclaimer4: "所有投资选择或基金价格均按照最新的结算日计算，并仅供参考。",
  disclaimer5: "人寿保单由汇丰人寿保险（国际）有限公司澳门分公司（“本公司”）承保，本公司获澳门金融管理局（“澳门金管局”）授权及受其监管，于澳门特别行政区经营长期保险业务。本公司是于百慕达注册成立之有限公司。本公司为汇丰集团旗下从事承保业务的附属公司之一。以上计划由本公司所承保而非香港上海汇丰银行有限公司之产品，并只拟在澳门特别行政区销售。",
  disclaimer6: "您的利益受本公司的信贷风险所影响。支付的保费将成为本公司资产的一部分，您对任何该等资产均没有任何权利或拥有权。如追讨赔偿，您只可向本公司追索。对于汇丰人寿保险（国际）有限公司与您之间因销售过程或处理有关交易而产生的合资格争议（定义见金融消费纠纷调解计划的受理范围），本公司须与您进行金融消费纠纷调解计划程序；此外，有关涉及您上述保单条款及细则的任何纠纷，将直接由本公司与您共同解决。",
  address_tips: "请更新您的电子邮箱",
  joint_policyholder: "联名持有人（英文姓名）",
  per_Day: "每天",
  no_plan: "此保障已不再就此计划生效",
}
