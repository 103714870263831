<template>
  <div class="capture_photo">
    <input @change="handleInput" class="add_pic_input" aria-label="请拍照"
      type="file" name="pic" accept="image/*" :capture="front ? 'user' : 'environment'"/>
  </div>
</template>

<script lang="ts" setup>

import { ref } from 'vue'

type FileInfo ={
  lastModified: number,
    size: number,
    name: string
}
const props = defineProps({
  front: Boolean,
  maxWidth: {
    type: Number,
    default: 800
  },
  maxHeight: {
    type: Number,
    default: 500
  },
  scale: {
    type: Number,
    default: 0.5
  }
})

const myEmit = defineEmits(['handleImage'])

const handleInput = (event) => {
  compressImage(event.target.files[0], event.target)
}

const compressImage = (file, target) => {
  // 留存图片信息避免重复上传
  const fileInfo: FileInfo = {
    lastModified: file.lastModified,
    size: file.size,
    name: file.name
  }
  let reader = new FileReader()
  reader.onload = function (event: any) {
    const originResult = event.target.result
    console.log('压缩前 result.length', originResult.length)
    const image = new Image()
    image.onload = () => {
      console.log('原始的宽度width:', image.width)
      let wh: any = getImageWH(image)
      //
      let canvas = document.createElement('canvas')
      canvas.width = wh.width
      canvas.height = wh.height
      let context = canvas.getContext('2d')
      context.drawImage(image, 0, 0, wh.width, wh.height)

      // 返回校正图片
      function getCompressData() {
        let result = canvas.toDataURL('image/jpeg', props.scale)
        console.log('压缩后 result.length', result.length)

        // 新的接口，支持过更强大的功能
        myEmit('handleImage', result, fileInfo)
        canvas = null
      }
      getCompressData()
      target.value = ''
    }
    image.src = originResult
  }
  reader.readAsDataURL(file)
}

const getImageWH = (image) => {
  let obj = {
    width: 0,
    height: 0
  }

  if (image.width > image.height) {
    obj.width = image.width > props.maxWidth ? props.maxWidth : image.width
    obj.height = (image.height * obj.width) / image.width
  } else {
    obj.height = image.height > props.maxHeight ? props.maxHeight : image.height
    obj.width = (image.width * obj.height) / image.height
  }

  return obj
}
</script>

<style lang="scss" scoped>
.capture_photo {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.add_pic_input {
  width: inherit;
  height: inherit;
}
</style>
