<template>
  <div class="id-card-ocr-confirm">
    <posHeader>
      <p>确认</p>
    </posHeader>

    <div class="confirm-content">
      <div>
        <p class="text-gery">姓名</p>
        <p>{{ infoData['clientName'] }}</p>
      </div>
      <div>
        <p class="text-gery">证件类型</p>
        <p>{{ infoData['idTypeName'] }}</p>
      </div>
      <div>
        <p class="text-gery">证件号码</p>
        <p>{{ infoData['idNumber'] }}</p>
      </div>
      <div>
        <p class="text-gery">证件有效期</p>
        <p>{{ infoData['idNumberExpiryDate'] }}</p>
      </div>
    </div>

    <div class="button-fixed">
      <div class="btn" @click="Confirm">确认信息</div>
    </div>

  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { useRouter } from "vue-router"
import { loading, Session, showModal } from '@/utils/commonMethod'
import SK from '@/common/constant/sessionStorageKey'

const router = useRouter()

const infoData = ref({})

onMounted(() => {
  const idvDataInfoData = Session.get(SK.IDV_DATA_INFODATA)
  if (idvDataInfoData.idNumber) {
    idvDataInfoData.idNumber = `${idvDataInfoData.idNumber.substr(0, 4)}**********${idvDataInfoData.idNumber.substr(idvDataInfoData.idNumber.length - 4, 4)}`
  }
  infoData.value = idvDataInfoData
})

const Confirm = () => {
  Session.set(SK.FORWARD_FLAG, 'Y')
  router.push({
    path: '/common/idv/cn/faceVerification'
  })
}

</script>

<style scoped lang="scss">
@import "@/style/fragment/button-fixed";
@import "@/style/fragment/border1px";

.id-card-ocr-confirm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: white;
  overflow: auto;

  .header-img {
    width: 18px;
  }

  .header-right {
    font-size: 13px;
    color: #333;
  }

  .confirm-content {
    background: white;
    position: relative;

    &::after {
      @extend %border;
    }

    & > div {
      min-height: 3.5em;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px;
      font-size: 14px;
      color: #333;
      background: white;

      &:not(:last-child)::after {
        @extend %border;

        left: 15px;
      }
    }
  }
}

.text-gery {
  color: #767676;
}

.bg-red {
  background: $red;
}
</style>
