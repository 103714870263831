<template>
  <div class="public-popup-box" @touchmove.prevent>
    <div class="content">
      <div class="text-box">
        <p class="title">{{ title || '提示' }}</p>
        <p class="text" v-html="content"></p>
        <ul class="circle" v-if="listArr.length > 0">
          <li v-for="(item, i) in listArr" :key="i">{{ item }}</li>
        </ul>
      </div>
      <div class="bt_group" v-if="!hideButton">
        <div v-if="showCancel" class="btn cancel" @click="clickCancel()">{{ cancelText || '取消' }}</div>
        <div class="btn confirm" @click="clickConfirm()">{{ confirmText || '确定' }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, ref, render } from "vue"

const props = defineProps({
  title: String,
  content: String,
  showCancel: {
    type: Boolean,
    default: false
  },
  hideButton: {
    type: Boolean,
    default: false
  },
  cancelText: String,
  confirmText: String,
  confirm: Function,
  cancel: Function,
  div: HTMLDivElement,
  listArr: {
    type: Array,
    default: function () {
      return []
    }
  }
})

const clickConfirm = () => {
  props.confirm()
  render(null, props.div)
}

const clickCancel = () => {
  props.cancel()
  render(null, props.div)
}

</script>

<style scoped lang="scss">
@keyframes zoomin {
  0% {
    transform: scale(1.1);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.public-popup-box {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #0006;
  z-index: 999;

  .content {
    width: 270px;
    background: #ffffffee;
    animation: zoomin 0.1s linear 0s;

    .text-box {
      padding: 25px 15px 15px 15px;

      .title {
        color: #000;
        text-align: center;
        font-size: 19px;
        font-weight: bold;
        line-height: 1em;
      }

      .text {
        margin-top: 7px;
        color: $d-color;
        font-size: 14px;
        font-family: inherit;
        line-height: 20px;
      }
    }

    .bt_group {
      display: flex;
      height: 44px;
    }

    .btn {
      height: 44px;
      width: 100%;
      text-align: center;
      line-height: 44px;
      font-size: 16px;
      border-top: 1px solid #08080833;
    }

    .cancel {
      color: #333;
      border-right: 1px solid #08080833;
    }

    .confirm {
      color: #db0011;
      font-weight: bold;
    }

    .circle {
      list-style-type: disc;
      color: #333;
      font-size: 14px;
      padding: 0 15px;
    }
  }
}
</style>
