/**
 * IdTypeEnum <br/>
 * LIS中的定义 <br/>
 * 0 身份证

1 护照

E 台湾居民来往大陆通行证

F 港澳居民来往内地通行证
J 港澳台居民居住证
K 外国人永久居留身份证
2 军官证
3 驾驶证
4 居民户口簿
8 其他证件
9 税务登记证
A 士兵证
B 回乡证
C 居留证
H 出生证
 */
 enum IdTypeEnum {
  /** 身份证 */
  CN_ID_CARD = "0",
  /** 居民户口簿 */
  HUKOU = "4",
  /** 护照 */
  PASSPORT = "1",
  /** 台湾居民来往大陆通行证 Mainland travel permit for Taiwan residents */
  TWJMLWDLTXZ = "E",
  /** 港澳居民来往内地通行证 */
  GAJMLWNDTXZ = "F",
  /** 港澳台居民居住证 */
  GATJMJZZ = "J",
  /** 外国人永久居留身份证 */
  WGRYJJLSFZ = "K",
  /** 出生证 */
  BIRTHCERTIFICATE = "H",
  /** 其他 */
  OTHER = "OTHER",
}

export default IdTypeEnum
