<template>
  <posHeader>
    <div>身份证件验证</div>
  </posHeader>
  <router-view/>
</template>

<script lang="ts" setup>

import { ref } from 'vue'
import SK from '@/common/constant/sessionStorageKey'
import IdvConfig from '@/common/components/idv/bean/IdvConfig'
import { useRouter } from 'vue-router'

const router = useRouter()

let idvConfig: IdvConfig = JSON.parse(sessionStorage.getItem(SK.IDV_CONFIG))

</script>

<style lang="scss" scoped>
.header-img {
  width: 18px;
}
</style>
