export default {
  upload_documents: "提交申請",
  upload_tips1: "您可使用此服務提出保單相關服務申請、申請索償及上載有關文件。",
  upload_tips2: "開始上載文件前，請檢查您需提交的服務申請類型是否支援電子提交，以及是否需要於表格上簽名。",
  upload_tips3: "是否需要於表格上簽名",
  applicable_forms: "適用表格",
  table_title1: "保單服務申請",
  table_title2: "是否需要簽名",
  table_title3: "支援電子提交",
  table_td1: "更改客戶資料",
  table_td2: "更改受益人",
  table_td3: "更改保單款項方式",
  table_td4: "更改保單保障",
  table_td20: "更改繳付保費指示",
  table_td5: "復效保單",
  table_td6: "退保或取消保單",
  table_td7: "保單貸款／基金提取",
  table_td8: "申請年結通知書、保險單年結摘要、實體醫療卡申領／更換或保單副本",
  table_td9: "補充文件",
  table_td10: "共同匯報標準表格",
  table_title4: "不支援電子提交",
  table_td11: "權益轉讓作為抵押擔保",
  table_td12: "保單權益轉讓",
  table_td13: "更改滙健優越醫療保險計劃保單保障",
  table_title5: "索償申請",
  table_title6: "支援電子提交",
  table_td14: "滙健優越醫療保險計劃預先批核",
  table_td15: "滙健優越醫療保險計劃醫療索償",
  table_td16: "住院現金或失業保障索償",
  table_td17: "嚴重疾病、女性保障或末期疾病索償",
  table_td18: "傷殘保障索償",
  table_td19: "身故賠償",
  upload_tips4: "如您想提交的表格不支援上載文件功能，請前往分行提交，或郵寄至以下地址︰",
  upload_tips5: "滙豐人壽保險（國際）有限公司澳門南灣大馬路619號時代商業中心1字樓",
  upload_tips6: "支援電子提交的表格可按以下3個步驟提交指示。",
  step1: "第1步",
  step1_text: "點按「下載表格」後，尋找並下載所需申請表格。",
  step1_button: "下載表格",
  step2: "第2步",
  step2_text: "填寫所需資料並簽署表格（如適用）。您可以使用裝置上的PDF檢視工具填寫表格。",
  step3: "第3步",
  step3_text: "確認備妥保單號碼及已填妥的申請表後，點按「上載文件」，並按滙豐網站上的指示上載適用表格及證明文件。",
  step3_button: "上載文件",
  upload_tips7: "詳情請查閱WeChat電子保單服務內的常見問題。",
  leave_text1: "您是否確定離開此頁面？",
  leave_text2: "如確定離開此頁面，您將前往澳門滙豐網站內的表格及文件下載頁面。",
  leave_button1: "離開",
  leave_button2: "留在此頁",
  leave_text3: '如確定離開此頁面，您將前往澳門滙豐網站內的上載文件頁面。',
  pageTips: "詳情請查閱滙豐保險澳門線上客戶服務內的常見問題。",
}
