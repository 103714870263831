import {
  RouteRecordRaw
} from 'vue-router'
import CN from '@/common/components/idv/CN.vue'
import IdCardOCR from '@/common/components/idv/IdCardOCR.vue'
import IdCardOCRConfirm from '@/common/components/idv/IdCardOCRConfirm.vue'
import FaceVerification from '@/common/components/idv/FaceVerification.vue'
import NonCN from '@/common/components/idv/NonCN.vue'
import OtherCardOCR from '@/common/components/idv/OtherCardOCR.vue'
import test from '@/common/components/idv/test.vue'

const commonRoutes: Array<RouteRecordRaw> = [
  {
    path: '/common/idv/cn',
    component: CN,
    children: [
      {
        path: 'test',
        component: test
      },
      {
        path: 'idCardOCR',
        component: IdCardOCR
      },
      {
        path: 'idCardOCRConfirm',
        component: IdCardOCRConfirm
      },
      {
        path: 'faceVerification',
        component: FaceVerification
      },
    ]
  },
  {
    path: '/common/idv/noncn',
    name: 'NonCN',
    component: NonCN,
    children: [
      {
        path: 'otherCardOCR',
        component: OtherCardOCR
      },
      {
        path: 'faceVerification',
        component: FaceVerification
      }
    ]
  },
  // 不能back()的公共结果页，只能返回首页
  {
    path: '/common/error',
    name: 'CommonError',
    meta: { title: '提示', disableBack: true },
    component: () => import('@/common/components/error/error.vue')
  },
  {
    path: '/common/errorBack',
    name: 'CommonErrorBack',
    meta: { title: '提示' },
    component: () => import('@/common/components/error/errorBack.vue')
  },
  {
    path: '/common/surrender/portal/:timestamp/:signature',
    name: 'SurrenderPortal',
    meta: {
      directOpen: true,
      disableBack: true
    },
    component: () => import('@/common/components/SurrenderPortal.vue')
  }
]

export default commonRoutes
