import { createVNode, render } from 'vue'
import dialog from './dialog.vue'

const div = document.createElement('div')
div.setAttribute('class', 'common-dialog-container')
document.body.appendChild(div)

export type DialogConfig = {
  title?: string,
  content: string,
  showCancel?: boolean,
  hideButton?: boolean,
  cancelText?: string,
  confirmText?: string,
  confirm?: Function,
  cancel?: Function,
  div?: HTMLDivElement,
  listArr?: Array<any>
}

export default (dialogConfig: DialogConfig) => {
  return new Promise((resolve, reject) => {
    const confirm = () => {
      console.log('dialog confirm')
      render(null, div)
      resolve(true)
    }
    const cancel = () => {
      render(null, div)
      // return reject()
    }
    if (!dialogConfig.confirm) {
      dialogConfig.confirm = confirm
    }
    if (!dialogConfig.cancel) {
      dialogConfig.cancel = cancel
    }
    dialogConfig.div = div
    const vnode = createVNode(dialog, dialogConfig)
    render(vnode, div)
  })
}
