const productCategory = [
  {
    value: "Annuity",
    EN: "Annuity",
    TC: "年金",
    SC: "年金"
  },
  {
    value: "Endowment",
    EN: "Endowment",
    TC: "儲蓄",
    SC: "储蓄"
  },
  {
    value: "Universal",
    EN: "Universal life",
    TC: "萬用壽險",
    SC: "万用寿险"
  },
  {
    value: "Unit-Link",
    EN: "Unit-linked life",
    TC: "投資相連壽險",
    SC: "投资相连寿险"
  },
  {
    value: "Whole",
    EN: "Whole Life",
    TC: "終身壽險",
    SC: "终身寿险"
  },
  {
    value: "Term",
    EN: "Term",
    TC: "定期",
    SC: "定期"
  },
  {
    value: "Jade",
    EN: "Jade",
    TC: "翡翠",
    SC: "翡翠"
  },
  {
    value: "Medical",
    EN: "Medical",
    TC: "醫療",
    SC: "医疗"
  },
  {
    value: "Critical",
    EN: "Critical illness",
    TC: "危疾",
    SC: "危疾"
  },
  {
    value: "Goal",
    EN: "Goal access",
    TC: "駿富",
    SC: "骏富"
  },
  {
    value: "CSPP",
    EN: "CSPP",
    TC: "CSPP",
    SC: "CSPP"
  },
  {
    value: "Exquisite",
    EN: "Exquisite",
    TC: "Exquisite",
    SC: "Exquisite"
  },
  {
    value: "Non-Par",
    EN: "Term",
    TC: "定期",
    SC: "定期"
  },
  {
    value: "Splendid",
    EN: "Splendid",
    TC: "Splendid",
    SC: "Splendid"
  },
  {
    value: "Ulife",
    EN: "Ulife IPP SSPP",
    TC: "Ulife IPP SSPP",
    SC: "Ulife IPP SSPP"
  },
]

export default productCategory
