export default {
  logo_name: "汇丰保险",
  select_language: "选择语言",
  confirm: "确定",
  name: "英文全名",
  name_placeholder: "请",
  name_error: "请勿输入英文字母和空格以外的字符。",
  ID_type: "身份证明文件类型",
  macao_card: "澳门特别行政区居民身份证",
  hk_card: "香港身份证",
  passport: "护照",
  other_card: "其他",
  ID_card: "身份证",
  ID_card_placeholder: "请输入您在投保时提供的身份证号",
  ID_number: "身份证明文件号码",
  idNumber_placeholder: "请输入您的身份证明文件号码",
  phone_number: "手机号码",
  phone_number_placeholder: "请输入手机号码",
  phone_error: "请输入正确的手机号码",
  ID_card_Travel: "台湾居民来往大陆通行证",
  ID_card_Travel_placeholder: "请输入您在投保时提供的台湾居民通行证号",
  phone_tips: "若无法输入在我司预留的手机号码，则请您输入当前使用的手机号码",
  phone_code: "验证码",
  phone_code_placeholder: "请输入验证码",
  phone_seconds: "秒后重新获取",
  read_agree: "我已阅读并同意",
  confirm_accept: "确认并同意",
  verification_code: "获取验证码",
  self_service_terms: "社交媒体使用规则、使用条款及细则",
  bind_now: "注册",
  my_policy: "保单摘要",
  more_services: "保单服务",
  support_title: "支持",
  file_upload: "提交申请",
  contact_customer: "联系我们",
  problem: "常见问题",
  home_tips1: "此服务为于澳门使用而设计。如您在海外使用此服务，请查阅跨境披露声明：",
  home_tips2: "此平台内所提供的服务仅供澳门客户使用。",
  home_tips3: "此服务由汇丰人寿保险（国际）有限公司澳门分公司（“本公司”）承保，本公司获澳门金融管理局（“澳门金管局”）授权及受其监管，于澳门特别行政区经营长期保险业务。",
  home_tips4: "如您不是身处澳门特别行政区，我们无法保证有权透过此网站在您身处或居住的国家或地区提供相关的服务。",
  home_tips5: "就任何人士在任何司法管辖区使用此网站会违反其法律或法规，或本公司未获发牌或授权在该等司法管辖区提供此平台及／或任何服务而言，本行无意让任何身处该等司法管辖区的人士使用此平台。",
  log_out: "登出",
  first_tips: '如需注册汇丰保险澳门线上客户服务，您必须持有',
  first_tips_list1: "至少一份汇丰人寿保险（国际）有限公司澳门分公司发出的有效保单",
  first_tips_list2: "有效的已注册手机号码",
  kind_tips: "注意事项",
  know: "我知道了",
  name_tips: "请按顺序：姓氏、名字、其他名字（如有）输入您身份证明文件上的全名，例如Chan Tai Man David。",
  idNumber_tips: "如属澳门特别行政区居民身份证，请连同输入该证件上的完整证件号码（包括括号和斜杠），例如9/999999/9或9999999(9)。如属香港身份证，请连同括号输入该证件上的完整证件号码（包括括号），例如A123456(X)或AB123456(X)。",
  phoneNumber_tips: "请输入投保时提供的手机号码。",
  idType_tips: "请选择您投保时提供的身份证明文件类别。",
  idNumberM_error: "请输入正确的澳门特别行政区居民身份证号码。",
  idNumberHK_error: "请输入正确的香港身份证号码。",
  Macau_areaCode: "澳门特别行政区 +853",
  HK_areaCode: "香港特别行政区 +852",
  China_areaCode: "中国内地 +86",
  Taiwan_areaCode: "台湾地区 +886",
  Canada_areaCode: "加拿大 +001",
  Singapor_areaCode: "新加坡 +65",
  bind_error1_title: "验证码错误",
  bind_error1: "您输入的验证码错误。请重新输入或获取新验证码后再试一次。",
  bind_error2_title: "手机号码纪录不符",
  bind_error2: "您输入的手机号码与我们的纪录不符。请确保您输入投保时提供的手机号码。",
  bind_error3_title: "没有持有有效保单",
  bind_error3: "根据我们的纪录，您没有在汇丰人寿保险（国际）有限公司澳门分公司持有有效的保单。您需持有至少一份有效的保单，才可注册此服务。",
  bind_error4_title: "没有相关纪录",
  bind_error4: "您输入的资料与我们的纪录不符。请确保您输入正确的英文全名、身份证明文件和其号码。",
  bind_error5_title: "无法连线",
  bind_error5: "目前无法连线至服务器，请重试。",
  back_btn: "返回",
  vanPicker: {
    confirm: "确认",
    cancel: "取消",
  },
  finish_btn: "完成",
  register_success_title: "确认注册",
  register_success: "已成功注册",
  register_success_text1: "您现在能在微信平台上使用相关服务。您将在",
  register_success_text2: "秒后自动前往服务页面",
  register_success_btn: "查看各种服务",
  smsErrorText1: "请输入您已获得的验证码。",
  smsErrorText2: "验证码错误，请检查后再试一次。",
  smsErrorText3: "验证码已通过短信发送至您提供的手机号码。",
  // Re-verification
  Re_title: "验证信息",
  Re_text1: "为保障您的账户安全，操作已逾时。",
  Re_text2: "请输入用作注册的身份证明文件号码最后4位。",
  // bind_terms
  bind_terms_title: "社交媒体使用规则、使用条款及细则",
  bind_terms0: "WeChat",
  bind_terms1: "我们开设了澳门汇丰保险的WeChat专页。请即浏览或赞好我们的专页。",
  bind_terms2: "社交媒体的使用规则",
  bind_terms3: "我们致力与澳门客户保持紧密联系。您可通过赞好我们澳门汇丰保险的WeChat官方专页，以获得我们最新的资讯、动向、优惠及与我们互动。",
  bind_terms4: "基于保安和保密的理由，请勿于我们的专页透露任何个人账户资料及密码，我们亦不会回覆任何与保单资料有关的查询及接受任何银行指示。",
  bind_terms5: "有关保单资料的查询，请致电客户服务热线与我们联系。",
  bind_terms6: "澳门汇丰保险官方专页：hsbclifemacau",
  bind_terms7: "澳门汇丰保险的WeChat专页及电子保单服务是用作和客户联系的渠道之一，请您遵守以下列出的简单规则。",
  bind_terms8: "澳门特别行政区（“澳门”）的汇丰人寿保险（国际）有限公司澳门分公司（注册成立于百慕达之有限公司）（“汇丰保险”）强烈不建议您于任何社交媒体上，分享属于您的任何个人资料，如您的姓名、地址、出生日期、银行账户号码、信用卡号码、保单号码及有关资料等。若因上述分享而引致损失，您须自行负责。汇丰保险不会要求您通过社交媒体发布任何您的个人相关资料。",
  bind_terms9: "请勿于发布内推广、建议或鼓吹以下行为：",
  bind_terms10: "- 赌博或任何和线上赌场、运动投注、宾果或扑克游戏相关的内容",
  bind_terms11: "- 枪械、武器、军火使用或任何违法药品、性交易或色情",
  bind_terms12: "- 裸露、亵渎或其他成人内容、暴力，或烟酒产品使用",
  bind_terms13: "- 针对任何特定人士、政府或组织的不当言论，或挑战或试图推翻任何国家政府",
  bind_terms14: "发布也请勿涉及以下议题：",
  bind_terms15: "不当猥亵、毁谤中伤等文字和不合法的内容",
  bind_terms16: "侵犯或违反任何第三方权益的内容，包括版权、商标、私隐、发布或其他个人或所有权的权利",
  bind_terms17: "误导或诈骗的内容",
  bind_terms18: "恶意、歧视、轻蔑或诋毁的内容，不论是否针对某个人、团体或其他网站使用者，以及不论是否基于种族、年龄、性别、身心障碍、性取向、宗教民族性、政治党派、国籍、祖先、婚姻状况、退伍军人身份等，或发布影响某产品、服务、行业或组织的针对性内容",
  bind_terms19: "泄漏机密资料，违反任何保密责任",
  bind_terms20: "任何病毒、骇客与恶意软体，或其他意图毁损电脑设备运作的程式",
  bind_terms21: "垃圾广告信息或非与汇丰相关的优惠、产品、服务或网页等",
  bind_terms22: "欢迎您分享我们的内容并给我们赞好，但我们不会于WeChat上自动赞好任何用户。若因任何情况我们赞好了您，亦不表示我们作任何形式的认同。",
  bind_terms23: "我们会密切留意大家对于汇丰保险在WeChat上的内容，也非常重视您宝贵的意见。但由于WeChat是公众平台，我们不方便在此回应或处理您所有的疑问。",
  bind_terms24: "汇丰保险不允许利用以私人信息讨论您的保单或个人资料。有关保单或个人资料的查询，请致电客户服务热线与我们联系。",
  bind_terms25: "使用条款及细则",
  bind_terms26: "澳门特别行政区（“澳门”）的汇丰人寿保险（国际）有限公司澳门分公司（注册成立于百慕达之有限公司）（“汇丰保险”或“本公司”）通过社交媒体的渠道、网页及账户，以通知、协助客户或与客户联系。除另有说明外，上述服务只适用于澳门及其澳门客户使用。社交媒体上关于本行的意见及内容将被记录，以便我们改善服务。",
  bind_terms27: "除非是本公司发布的资料，否则本公司不会对任何非本公司于社交网站所发表的意见及内容承担责任。本公司对于社交网站本身、其产品与服务，或任何第三者或其他社交网站使用者于社交网站所发表的任何信息均不表示确认或作任何保证。使用社交媒体（如WeChat）与本公司联系，您须确保遵守本公司及社交媒体的使用条款及细则和使用政策，您同意不会在使用本社交网站时参与任何违反适用法律、法规或违反您与本公司之间任何协议条款的活动。",
  bind_terms28: "本公司于本社交网站上的内容只供提供资讯作参考，不构成要约或招揽任何人购买本公司的产品或服务。本公司不会通过社交媒体网站向您提供投资、税务或其他专业意见。您与本公司于本社交网站上的通讯亦不会改变或取代您作为本公司客户时与本公司订立的任何协议。",
  bind_terms29: "当您通过社交媒体与本公司联系时，您的个人资料可能被保存在该社交媒体网站的服务器上，并不受本公司管控。关于本公司如何运用您的个人资料，请参照私隐政策。您发表在任何社交网站上的信息，一般大众亦能取得也能够浏览。社交网站平台并不适合您讨论个人账号资料或财务安排。因此，本公司不会在社交媒体网站要求您提供任何个人、保单或保安的资料，您也不应在社交网站上张贴该等资料。在不变动本公司发表的内容与注明资料来源的前提下，您可以转发或回应本公司在本公司社交网站上的内容。所有本公司发布于本公司社交网站上的内容，均受本行的版权所保护。本公司于社交网站上所使用或展示的任何汇丰保险的商标、徽号和设计，均为汇丰保险的知识产权。",
  bind_terms30: "本公司或任何其他参与制作或发表本社交网站资讯的人士、其职员、董事、雇员或代表，不会对因本社交网站、使用本社交网站或其连结网站，或延迟或不能使用本社交网站或其连结网站所造成任何形式之间接、特殊、惩罚或附带损失而负法律责任（包括但不限于涉及利益损失、寻找替代服务之费用或使用条款及细则损失机会所产生的损失），不论本公司是否知悉有可能会招致该等损失与否。此限制包括但不限于散布可能对使用者的电子设备造成损害的病毒、资料或有害的程式，档案与使用者浏览器或其他网站连结程式的技术问题，电子设备或电信设备、传输或连结网络的故障，未授权存取、偷窃、操作错误或任何不可抗力的因素。本公司不保证与此社交网站或其连结网站的使用是持续，不受干扰或安全的。网站所显示内容、准确性，意见或提供的其他连结，本行并无义务调查、核实、监督或确认。社交网站或连结网站上的资料、软件、产品和服务内容，可能是不完整、错误或遗漏内容，本公司在此特别声明不就该等不完整、错误或遗漏负相关责任。本公司将定时或随时更新及修改网站内容、资讯及链接，以及改善本网站。",
  bind_terms31: "您同意免除本公司及其母公司、附属公司、子公司、职员、董事、代理、雇员、服务供应商和所有与本网站内容制作执行相关之人士的责任，包括但不限于因暂停、修改、取消本社交网站、技术问题、接受或发布贴文所造成的，当中包括人身伤害、死亡、损害或损失的责任。当您进入、浏览、存取、下载档案、搜寻或以其他方式使用本网站，您已同意若您的贴文违反本使用条款及细则，造成本公司、其代理、服务供应商和其各自的母公司、子公司、附属公司、继承人、被授权人及受让人，以及其各自的董事、职员、代理、股东、雇员的所有任何申索、损失、成本、损害、法律责任及费用（包含合理的律师费），您均同意负全部赔偿之责。",
  bind_terms32: "本条款及细则受现行的监管规定所约束。",
  bind_terms33: "本条款及细则受澳门法律管辖并按其诠释。",
  bind_terms34: "本公司跟随任何其他WeChat的使用者、转推或分享其他任何第三方的内容不等于确定或赞同该等资讯。",
  bind_terms35: "本公司目前在澳门于WeChat的官方专页为：hsbclifemacau",
  bind_terms36: "本条款及细则的中英文版本如有歧义或不一致，概以英文版本为准。",
}
