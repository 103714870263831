import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router/index.router'
import { key, store } from '@/store/index.store'
import vantImport from '@/utils/vantImport'
import commonComponentImport from '@/utils/commonComponentImport'
import buildCustomDirective from '@/utils/customDirective'
import { getBrowserType } from '@/utils/commonMethod'
import i18n from './language'

// 初始化环境数据
getBrowserType()
const app = createApp(App)
app.use(router)
// 需要this.$store有类型声明的话参考关键词： ComponentCustomProperties
app.use(store, key)
// 按需引入vant组件
vantImport(app)
// 引入全局组件
commonComponentImport(app)
// 自定义指令
buildCustomDirective(app)

app.use(i18n)

app.mount('#app')

/* eslint-disable no-console */
console.log('----env----', import.meta.env)
