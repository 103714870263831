export default {
  policy_period: "Policy period",
  policy_details: "Policy details",
  policy_tab1: "Basic plan coverage",
  policy_tab2: "Supplementary benefits",
  policy_number: "Policy number",
  total_account: "Total account value",
  view_details: "View fund details",
  policyholders_title: "Policyholders and lives insured",
  policyholder: "Policyholder",
  life_insured: "Life insured ",
  contact_infor: "Contact information",
  phone_number2: "Mobile phone number",
  policy_address: "Policy correspondence address",
  email_address: "Email address",
  sum_insured_title: "Insurance coverage",
  sum_insured: "Coverage amount",
  sum_insured_tips: "Coverage amount - 'coverage amount' refers to the the sum insured, policy amount, notional amount or initial monthly guaranteed annuity payment where applicable to your policy.Sum insured - 'sum insured' refers to the amount of money that the insurance company may pay in the occurrence of an event such as death or being diagnosed with diseases listed in the policy provisions.Policy amount - 'policy amount' is the amount used to determine the premiums payable under the policy, and the cash values along with special bonuses or terminal bonuses (if applicable) to be received under the basic plan of the policy. It doesn't represent the amount of the death benefit payable or the cash value of the policy.Notional amount - 'notional amount' refers to the amount used to determine the premiums payable, and your cash values, special bonuses and amounts of benefit to be received (if any) under the basic plan of this policy. This notional amount doesn't represent the amount of the death benefit.Initial monthly guaranteed annuity payment - 'initial monthly guaranteed annuity payment' refers to the guaranteed payment to be received monthly during the annuity period. Refer to policy provisions for more details.For more details, please refer to the policy provisions.",
  policy_date: "Policy effective date",
  benefit_date: "Benefit end date",
  payment_information: "Payment information",
  premium_date: "Premium due date",
  payment_method: "Payment method",
  payment_mode: "Payment mode",
  in_force: "In force",
  disclaimer_title: "Disclaimer",
  disclaimer1: "The insurance plans listed on this page are underwritten by HSBC Life (International) Limited which is incorporated in Bermuda with limited liability.",
  disclaimer2: "The stated amounts are snapshot values as of now and for your reference only. The actual amount you'll get upon policy value withdrawal, surrender or maturity may be different as we can only ascertain certain charges or adjustment amounts upon actual payment time (such as but not limited to deducting surrender charge and making rounding adjustments). The amount may change depending on a number of reasons, such as your claim history, discount eligibility and premium financing arrangement. The amount shown here is the net payable amount after deducting any overpayment made previously. You can refer to the policy provisions for more details.",
  disclaimer3: "US laws may require us to reject specific policy servicing requests if the policyholder (or anyone else with authority over the policy) is physically located in or acting from the US (either temporarily or permanently) when making the request.",
  individual_sum: "Individual insurance coverage",
  individual_tips1: "Coverage amount refers to the sum insured, policy amount, notional amount or initial monthly guaranteed annuity payment where applicable to your policy.",
  individual_tips2: "Sum insured refers to the amount of money that the insurance company may pay in the occurrence of an event such as death or being diagnosed with diseases listed in the policy provisions.",
  individual_tips3: "Policy amount is the amount used to determine the premiums payable under the policy, and the cash values along with special bonuses or terminal bonuses (if applicable) to be received under the basic plan of the policy. It doesn't represent the amount of the death benefit payable or the cash value of the policy.",
  individual_tips4: "Notional amount refers to the amount used to determine the premiums payable, and your cash values, special bonuses and amounts of benefit to be received (if any) under the basic plan of this policy. This notional amount doesn't represent the amount of the death benefit.",
  individual_tips5: "Initial monthly guaranteed annuity payment refers to the guaranteed payment to be received monthly during the annuity period. Refer to policy provisions for more details.",
  individual_tips6: "For more details, please refer to the policy provisions.",
  sumInsured_tips: "Coverage amount refers to the sum insured, policy amount, notional amount or initial monthly guaranteed annuity payment where applicable to your policy.Sum insured refers to the amount of money that the insurance company may pay in the occurrence of an event such as death or being diagnosed with diseases listed in the policy provisions.Policy amount is the amount used to determine the premiums payable under the policy, and the cash values along with special bonuses or terminal bonuses (if applicable) to be received under the basic plan of the policy. It doesn't represent the amount of the death benefit payable or the cash value of the policy.Notional amount refers to the amount used to determine the premiums payable, and your cash values, special bonuses and amounts of benefit to be received (if any) under the basic plan of this policy. This notional amount doesn't represent the amount of the death benefit.Initial monthly guaranteed annuity payment refers to the guaranteed payment to be received monthly during the annuity period. Refer to policy provisions for more details.For more details, please refer to the policy provisions.",
  individual_sum_btn: "	Back to 'Policy details'",
  whole_life: "Whole of life",
  price: "Price",
  units: "Units",
  total_fund: "Total fund balance",
  disclaimer4: "All investment choices or fund prices are calculated as per the latest valuation date and are for reference only.",
  disclaimer5: "Life insurance policies are underwritten by HSBC Life (International) Limited, Macau Branch ('the Company'), which is authorised and regulated by the Autoridade Monetária de Macau ('AMCM') to carry on long-term insurance business in the Macau Special Administrative Region. The Company is incorporated in Bermuda with limited liability, and is one of HSBC Group's insurance underwriting subsidiaries. The product is a product of the Company and is intended only for sale in the Macau SAR.",
  disclaimer6: "Your benefit is subject to the credit risk of the Company. Your premiums paid will form part of the Company’s assets. You don't have any rights or ownership over any of those assets. Your recourse is against the Company only. In respect of an eligible dispute (as defined in the admissibility scope in the Mediation Scheme for Financial Consumption Disputes) arising between HSBC Life (International) Limited and you out of the selling process or processing of the related transaction, the Company is required to enter into a Financial Consumption Dispute Mediation process with you; however, any dispute over the contractual terms of the above insurance product should be resolved between the Company and you directly.",
  address_tips: "Please update your email address",
  joint_policyholder: "Joint policyholder",
  per_Day: "Per Day",
  no_plan: "This benefit is no longer active for this plan",
}
